import { Compiler, Component, Injector, Input } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UserService } from "@core/app/services/user.service";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { BehaviorSubject, combineLatest, from } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

@Component({
	selector: "cm-edit-widget",
	template: `
		<ng-container *ngIf="show$ | async">
			<div
				*ngIf="initialized && activeBS | async"
				class="edit-widget d-print-none"
				[ngClass]="{ active: activeBS | async, initialized: initialized }"
			>
				<ng-container *ngComponentOutlet="contents$ | async"></ng-container>
			</div>
			<div
				class="edit-widget-button d-print-none"
				[ngClass]="{ active: activeBS | async }"
				(click)="toggleWidget()"
			>
				<fa-icon [icon]="faEdit"></fa-icon>
			</div>
		</ng-container>
	`,
	styles: [
		`
			.edit-widget {
				position: fixed;
				top: 97px;
				right: 0;
				background: rgba(210, 210, 210, 0.85);
				height: calc(100vh - 97px);
				width: 0;
				max-width: 95%;
				z-index: 1050;
				overflow: scroll;
				-webkit-overflow-scrolling: touch;
				box-shadow: 1.5px 1.5px 3px 0 rgba(0, 0, 0, 0.5);
			}
			.edit-widget.active {
				width: 300px;
			}
			.edit-widget-button {
				position: fixed;
				top: 97px;
				right: 0;
				background: rgba(0, 58, 112, 0.231);
				padding: 2px 5px;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				cursor: pointer;
				z-index: 1050;
				box-shadow: 1.5px 1.5px 3px 0 rgba(0, 0, 0, 0.5);
			}
			.edit-widget-button fa-icon {
				color: #fff;
				font-size: 20px;
			}
			.edit-widget-button.active {
				right: 300px;
			}
			@media (max-width: 767.98px) {
				.edit-widget-button {
					background: #000;
				}
				.edit-widget-button.active {
					top: 0;
				}
				.edit-widget {
					top: 0;
					height: 100vh;
				}
			}
		`,
	],
})
export class EditWidgetComponent {
	@Input() data: any;

	activeBS = new BehaviorSubject(false);
	initialized: boolean = false;
	faEdit = faEdit;

	contents$ = combineLatest([this.userService.loggedIn$, this.activeBS]).pipe(
		filter(([loggedIn, active]) => loggedIn && active),
		switchMap(() =>
			from(
				(async () => {
					const module = await import("../edit-widget-contents/edit-widget-contents.module");
					const factory = await this.compiler.compileModuleAsync(module.EditWidgetContentsModule);
					factory.create(this.injector);
					return module.EditWidgetContentsModule.entry;
				})(),
			),
		),
	);

	show$ = combineLatest([this.userService.loggedIn$, this.userService.permissions$]).pipe(
		map(([loggedIn, perms]) => loggedIn && perms.hasPermission(["user-widget", "edit", "inventory"])),
	);

	constructor(
		private userService: UserService,
		private router: Router,
		private compiler: Compiler,
		private injector: Injector,
	) {
		combineLatest([this.router.events, this.activeBS])
			.pipe(filter(([event, _]) => event instanceof NavigationEnd))
			.subscribe(([_, active]) => {
				if (active) {
					this.toggleWidget();
				}
			});
	}

	toggleWidget() {
		this.activeBS.next(!this.activeBS.value);
		this.initialized = true;
	}
}
