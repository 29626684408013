import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	font: {
		family: "Roboto,sans-serif",
	},
	dealerInfo: {
		name: "aok",
		homeBreadcrumb: "RV Dealer",
		themeData: {
			dealerAccent: {
				bg: "#000",
				bgHover: "#000",
				fg: "#000000",
				fgHover: "#FFF",
				base: { bg: "#333", fg: "#FFF", light: "#aaa" },
				offset: "#fff",
				highlight: {
					bg: "#ff0208",
					bgHover: "#ff0208",
					fg: "#FFF",
				},
				homeUrl: "",
				footerUrl: "",
			},
		},
	},
};
