<div
	*ngIf="initialized && activeBS | async"
	class="cm-user-widget d-print-none"
	[ngClass]="{ active: activeBS | async, initialized: initialized, 'record-active': record }"
>
	<div class="cm-user-widget-menu">
		<ng-container *ngComponentOutlet="contents$ | async"></ng-container>
	</div>
</div>
<div
	class="cm-user-widget-gear flex justify-center items-center"
	[ngClass]="{ active: activeBS | async }"
	(click)="toggleWidget()"
>
	<fa-icon [icon]="faCogs"></fa-icon>
</div>
