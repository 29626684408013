import { CdkAccordionModule } from "@angular/cdk/accordion";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FaqComponent } from "./components/faq/faq.component";

@NgModule({
	declarations: [FaqComponent],
	imports: [CommonModule, FontAwesomeModule, CdkAccordionModule],
	exports: [FaqComponent],
})
export class FaqModule {}
