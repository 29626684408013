import { HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { IPageData } from "@model/page-data";
import { combineLatest, of } from "rxjs";
import { first, map, shareReplay, take, tap } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class DealerService {
	phoneNumber: any = null;
	address: any = {};
	dealerInfo$: any = this.transfer
		.transfer$("GetDealerInfo", () => this.http.post("/api/statement/GetDealerInfo", {}))
		.pipe(
			map((res: any) => res.results[0]),
			tap((res) => {
				this.address = {
					address1: res.address1,
					address2: res.address2,
					city: res.city,
					state: res.stateprov,
					zip: res.postal_code,
					country: res.country,
				};
				this.phoneNumber = res.phone_number;
			}),
			shareReplay(1),
		);

	constructor(
		@Inject("PAGE_DATA") private pageData: IPageData,
		private http: HttpClient,
		private transfer: TransferRxService,
	) {
		this.dealerInfo$.pipe(first()).subscribe();
	}

	logo$ = this.dealerInfo$.pipe(map((dealerInfo: any) => `${dealerInfo.img_dir}${dealerInfo.img_file}`));
	name$ = this.dealerInfo$.pipe(map((dealerInfo: any) => dealerInfo.dealer));

	phoneNumber$ = this.dealerInfo$.pipe(map((dealerInfo: any) => dealerInfo.phone_number));
	faxNumber$ = this.dealerInfo$.pipe(map((dealerInfo: any) => dealerInfo.faxNumber));

	city$ = this.dealerInfo$.pipe(map((dealerInfo: any) => dealerInfo.city));
	abbrev$ = this.dealerInfo$.pipe(map((dealerInfo: any) => dealerInfo.abbrev));
	postal_code$ = this.dealerInfo$.pipe(map((dealerInfo: any) => dealerInfo.postal_code));
	addressLine1$ = this.dealerInfo$.pipe(
		map((dealerInfo: any) => `${dealerInfo.address1}${dealerInfo.address2 || ""}`),
	);
	addressLine2$ = this.dealerInfo$.pipe(
		map((dealerInfo: any) => `${dealerInfo.city}, ${dealerInfo.abbrev} ${dealerInfo.postal_code}`),
	);
	addressLink$ = combineLatest([this.addressLine1$, this.addressLine2$]).pipe(
		map(([addressLine1, addressLine2]: any) => {
			const link1 = addressLine1.replace(/ /g, "+");
			const link2 = addressLine2.replace(/ /g, "+");
			return `https://www.google.com/maps/place/${link1},+${link2}`;
		}),
	);

	getAddress() {
		return {
			...this.address,
			/**
			 * Formats the Address returned
			 */
			format: function () {
				return [this.address1, this.address2, this.city, this.state + " " + this.zip]
					.filter((x: any) => x !== "")
					.join(", ");
			},
		};
	}

	getName() {
		return this.pageData.appInfo.data.siteName;
	}
}
