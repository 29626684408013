import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { UserService } from "@core/app/services/user.service";
import { FilterImageStyle, ISearchFilterOption } from "@model/search-filter-option";
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map, shareReplay, switchMap, tap } from "rxjs/operators";

const defaultFilters: IFilterSetting[] = [
	{ field: "vehicle_type", label: "Type", imageStyle: FilterImageStyle.Show },
	{ field: "brand", label: "Brand", imageStyle: FilterImageStyle.OnHover },
	{ field: "model_num", label: "Model", parent: "brand", sortField: "text", sortOrder: "asc" },
	{ field: "price_range", label: "Price Range" },
	{ field: "vehicle_section", label: "Floor Plan" },
	{ field: "length_range", label: "Length" },
	{ field: "weight_range", label: "Weight" },
	{ field: "model_year", label: "Year" },
	{ field: "keywords", label: "Search" },
];

@Injectable({ providedIn: "root" })
export class SearchFilterService {
	constructor(
		private router: Router,
		private transfer: TransferRxService,
		private userService: UserService,
		private http: HttpClient,
	) {}

	createState(route: ActivatedRoute): SearchFilterState {
		return new SearchFilterState(route, this.router, this.http, this.transfer, this.userService);
	}
}

export class SearchFilterState {
	searchUrl?: string;

	private loadingBS = new BehaviorSubject(false);
	loading$ = this.loadingBS.asObservable();

	filterSettingsBS = new BehaviorSubject<IFilterSetting[] | null>(null);
	filterSettings$: Observable<IFilterSetting[]> = this.filterSettingsBS.pipe(
		map((inputSettings) => inputSettings || defaultFilters),
	);

	private filtersRS = new ReplaySubject<any>(1);
	filters$ = this.filtersRS.asObservable();

	salesPersonBS = new BehaviorSubject<boolean>(false);
	salesPerson$ = this.userService.permissions$.pipe(
		map((perms) => perms.hasPermission(["crm", "sales"]) || perms.hasPermission(["crm", "manager"])),
	);

	usedFilters$ = combineLatest([this.filters$, this.filterSettings$]).pipe(
		distinctUntilChanged(),
		map(([filters, settings]: [any, any]) =>
			settings.filter(
				(setting: any) => !setting.parent || filters[setting.parent] || filters[setting.parent + "id"],
			),
		),
	);

	queries$ = this.usedFilters$.pipe(
		map((filters) =>
			filters
				.filter(
					(filter: any) =>
						filter.field !== "keywords" && filter.field !== "price" && filter.field !== "payment",
				)
				.map((filter: any) => ({ id: `Get${transformField(filter.field)}Filters` })),
		),
	);

	data$ = combineLatest([this.filters$, this.salesPerson$, this.queries$]).pipe(
		distinctUntilChanged(),
		debounceTime(300),
		map(([filterData, salesPerson, queries]) => {
			return { queries, salesPerson, global: { vars: filterData } };
		}),
	);

	filterOptions$ = this.data$.pipe(
		distinctUntilChanged(),
		tap(() => this.loadingBS.next(true)),
		switchMap((data) => {
			return this.transfer.transfer$(`Filter:${JSON.stringify(data)}`, () =>
				this.http.post("/api/statement/m", data),
			);
		}),
		tap(() => this.loadingBS.next(false)),
	);

	usedFiltersOptions$: Observable<ISearchFilterOption[]> = combineLatest([
		this.usedFilters$,
		this.filterOptions$,
		this.filters$,
	]).pipe(
		distinctUntilChanged(),
		map(([filters, options, filterParams]: any[]) => {
			return filters.map((filter: IFilterSetting) => {
				const queryName = "Get" + transformField(filter.field) + "Filters";
				const results = (() => {
					if (filter.field === "keywords" || filter.field === "price" || filter.field === "payment") {
						return null;
					} else if (options.queries[queryName]) {
						return options.queries[queryName].results;
					} else {
						return [];
					}
				})();
				const type = getFilterType(filter.field, results);
				return {
					key: queryName,
					results,
					label: filter.label,
					name: filter.field,
					type,
					sortField: filter.sortField || null,
					sortOrder: filter.sortOrder || null,
					queryParams: filterParams,
					imageStyle: filter.imageStyle,
					show$: filter.show$,
					col: filter.col,
				};
			});
		}),
		shareReplay(1),
	);

	private searchSubject = new Subject();
	search$ = this.searchSubject.pipe(
		switchMap(() => this.filters$),
		distinctUntilChanged((a, b) => a && b && JSON.stringify(a) === JSON.stringify(b)),
	);

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private http: HttpClient,
		private transfer: TransferRxService,
		private userService: UserService,
	) {}

	setFilterSettings(val: IFilterSetting[] | null) {
		this.filterSettingsBS.next(val);
	}

	setFilters(val: any) {
		this.filtersRS.next(val);
	}

	setSearchUrl(val?: string) {
		this.searchUrl = val;
	}

	prepareUrl() {
		if (this.searchUrl) {
			return this.searchUrl;
		} else {
			let ret;
			this.route.data
				.pipe(
					map((data) => {
						ret = data.routeData.stmt.results[0].content_page_url;
					}),
					first(),
				)
				.subscribe();
			return ret;
		}
	}

	pushToQueryParams(filter: ISearchFilterOption, event: any) {
		this.route.queryParams
			.pipe(
				first(),
				map((queryParams) => this.prepareChangeForQuery(filter, event, { ...queryParams })),
				tap((queryParams) => {
					this.router.navigate([this.prepareUrl()], { queryParams });
				}),
			)
			.subscribe();
	}

	resetQueryParams() {
		this.router.navigate([this.prepareUrl()], { queryParams: {} });
	}

	search() {
		this.searchSubject.next();
	}

	private prepareChangeForQuery(filter: ISearchFilterOption, event: any, queryParams: any) {
		switch (filter.type) {
			case "priceRange":
			case "range":
			case "paymentRange":
				return createRangeQueryParams();
			case "checkbox":
				return createCheckboxQueryParams();
			case "searchBox":
				return createSearchboxQueryParams();
			case "select":
				return createSelectQueryParams();
			case "predictive":
				return createPredictiveQueryParams();
			default:
				return queryParams;
		}

		function createRangeQueryParams() {
			const { min, max } = event;
			const minKey = `min_${filter.name}`;
			const maxKey = `max_${filter.name}`;

			// If there is a min value, add it to the queryParams. Otherwise delete it.
			if (min) {
				queryParams[minKey] = min;
			} else {
				delete queryParams[minKey];
			}

			// If there is a max value, add it to the queryParams. Otherwise delete it.
			if (max) {
				queryParams[maxKey] = max;
			} else {
				delete queryParams[maxKey];
			}
			return queryParams;
		}

		function createCheckboxQueryParams() {
			const filterKey = `${filter.name}id`;

			// The event will have a length if there are any boxes checked
			if (event && event.length) {
				queryParams[filterKey] = event.join(",");
			} else {
				delete queryParams[filterKey];
			}

			return queryParams;
		}

		function createSearchboxQueryParams() {
			const filterKey = `${filter.name}`;
			if (event && event.length) {
				queryParams[filterKey] = event;
			} else {
				delete queryParams[filterKey];
			}
			return queryParams;
		}

		function createSelectQueryParams() {
			const filterKey = `${filter.name}`;
			if (event && event.length) {
				queryParams[filterKey] = event;
			} else {
				delete queryParams[filterKey];
			}
			return queryParams;
		}

		function createPredictiveQueryParams() {
			const filterKey = `${filter.name}`;
			if (event && event.length) {
				queryParams[filterKey] = event;
			} else {
				delete queryParams[filterKey];
			}
			return queryParams;
		}
	}
}

export interface IFilterSetting {
	field: string;
	label: string;
	parent?: string;
	sortField?: string;
	sortOrder?: string;
	imageStyle?: FilterImageStyle;
	show$?: Observable<boolean>;
	col?: number;
}

function getFilterType(name: string, results: any[]) {
	if (name === "keywords") {
		return "searchBox";
	} else if (name === "price") {
		return "priceRange";
	} else if (name === "payment") {
		return "paymentRange";
	} else if (results[0] && (results[0].min || results[0].minMax)) {
		return "range";
	} else if (results[0] && results[0].dropdown) {
		return "select";
	} else if (results[0] && results[0].predictive) {
		return "predictive";
	} else {
		return "checkbox";
	}
}

function transformField(str: string): string {
	const frags = str.split("_");
	for (let i = 0; i < frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join("");
}
