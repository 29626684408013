import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { TransferRxService } from "@common/services/transfer-rx.service";
import {
	faFacebook,
	faGoogle,
	faInstagram,
	faPinterest,
	faTumblr,
	faTwitter,
	faWordpress,
	faYoutube,
	faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { ISocialLink } from "@model/social-link";

@Component({
	selector: "cm-social-links2",
	template: `
		<a
			*ngFor="let socialLink of socialLinks"
			href="{{ socialLink.link }}"
			target="_blank"
			itemprop="sameAs"
			title="{{ socialLink.desc }}"
			rel="noopener"
			class="social-link mx-1"
		>
			<fa-icon class="social-icon" [icon]="socialLink.icon" [size]="iconSize"></fa-icon>
		</a>
	`,
	styles: [],
})
export class SocialLinks2Component implements OnInit {
	socialLinks: ISocialLink[] = [];

	@Input() iconSize: string = "2x";

	constructor(private http: HttpClient, private transfer: TransferRxService) {}

	ngOnInit() {
		this.transfer
			.transfer$("GetDealerSocialAffiliateLinks", () =>
				this.http.post("/api/statement/GetDealerSocialAffiliateLinks", {}),
			)
			.subscribe((response: any) => {
				for (const item of response.results) {
					const current: ISocialLink = {
						desc: item.affiliate_name,
						link: item.site_affiliate_url,
					};
					switch (current.desc) {
						case "Facebook":
							current.icon = faFacebook;
							break;
						case "Pinterest":
							current.icon = faPinterest;
							break;
						case "Twitter":
							current.icon = faTwitter;
							break;
						case "Tumblr":
							current.icon = faTumblr;
							break;
						case "Instagram":
							current.icon = faInstagram;
							break;
						case "Youtube":
						case "YouTube":
							current.icon = faYoutube;
							break;
						case "Google":
						case "Google +":
							current.icon = faGoogle;
							break;
						case "Wordpress":
							current.icon = faWordpress;
							break;
						case "TikTok":
							current.icon = faTiktok;
							break;
					}
					if (current.icon) {
						this.socialLinks.push(current);
					}
				}
			});
	}
}
