import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FooterFnsComponent } from "./components/footer-fns/footer-fns.component";
import { SharedModule } from "@core/app/shared/shared.module";

@NgModule({
	declarations: [FooterFnsComponent],
	imports: [CommonModule, SharedModule],
	exports: [FooterFnsComponent],
})
export class FooterModule {}
