import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { iter, iterFiles } from "@core/app/common/iter";
import { RestService, Service } from "@core/app/services/rest.service";
import { ImagePipe } from "@core/app/image/pipes/image.pipe";
import { faFile } from "@fortawesome/pro-solid-svg-icons";
import { ToastrService } from "ngx-toastr";

const dropzoneConfig = {
	acceptedFiles: ".jpg, .png, .gif, .ico, .svg, .pdf",
	autoQueue: false,
	uploadMultiple: true,
	url: "dummy",
};

@Component({
	selector: "cm-modal-file-upload",
	templateUrl: "./modal-file-upload.component.html",
	styleUrls: ["./modal-file-upload.component.scss"],
	providers: [ImagePipe],
})
export class ModalFileUploadComponent implements OnInit {
	@Input() mode: "file" | "image" = "file";
	@Input() categoryFilter?: number[];
	@Input() stmtVars: { [key: string]: any } = {};

	@Output() close = new EventEmitter<any[]>();
	@Output() dismiss = new EventEmitter<void>();

	dropzoneConfig = dropzoneConfig;
	faFile = faFile;

	api?: IApiAdapter;
	categories?: any[];
	uploadedFiles: FileObj[] = [];
	url: string = "";
	urlCategory: string = "";
	uploading: boolean = false;

	constructor(private toastrService: ToastrService, private rest: RestService, private imagePipe: ImagePipe) {}

	async ngOnInit() {
		if (this.mode === "file") {
			this.api = new FileApiAdapter(this.rest);
		} else {
			this.api = new ImageApiAdapter(this.rest, this.imagePipe);
		}

		this.categories = await this.api.getCategories(
			this.categoryFilter && this.categoryFilter.length > 0 ? new Set(this.categoryFilter) : undefined,
		);
	}

	async valueChange(category: number, files: File[]) {
		this.uploading = true;

		try {
			this.uploadedFiles = await this.api!.upload(files, null, category, this.stmtVars);
		} catch (ex) {
			this.toastrService.error(ex.error.message);
			console.error(ex);
			return;
		} finally {
			this.uploading = false;
		}

		this.toastrService.success("Upload Successful");
	}

	onCatClick(category: number) {
		const input = document.createElement("input");
		input.type = "file";
		input.multiple = true;
		input.onchange = () => this.valueChange(category, iterFiles(input.files!).toArray());
		input.click();
	}

	onFileClick(image: FileObj) {
		image.selected = !image.selected;
	}

	async onImport() {
		this.uploading = true;

		try {
			this.uploadedFiles = await this.api!.upload([], this.url, parseInt(this.urlCategory), this.stmtVars);
		} catch (ex) {
			this.toastrService.error(ex.error.message);
			console.error(ex);
			return;
		} finally {
			this.uploading = false;
		}

		this.toastrService.success("Upload Successful");
	}

	onSelect() {
		this.close.emit(
			iter(this.uploadedFiles)
				.filter((f) => f.selected)
				.map((f) => f.obj)
				.toArray(),
		);
	}
}

interface IApiAdapter {
	getCategories(categories?: Set<number>): Promise<Category[]>;
	upload(files: any[], url: string | null, category: number, stmtVars: { [key: string]: any }): Promise<FileObj[]>;
}

class ImageApiAdapter implements IApiAdapter {
	service: Service;
	statement: Service;

	constructor(rest: RestService, private imagePipe: ImagePipe) {
		this.service = rest.init("image-library/v1");
		this.statement = rest.init("statement");
	}

	async getCategories(categories?: Set<number>): Promise<Category[]> {
		const params = categories ? { vars: { img_catids: iter(categories.keys()).toArray() } } : {};
		const response = await this.statement.post("GetImgCats", params);
		return response.results.map((row: any) => new Category(row.img_catid, row.img_cat));
	}

	async upload(
		files: File[],
		url: string | null,
		category: number,
		stmtVars: { [key: string]: any },
	): Promise<any[]> {
		let images: any[] = [];

		if (url) {
			images = await this.sendReq([], url, category, stmtVars);
		} else {
			const reqLimit = 5 * 1024 * 1024;
			let reqFiles: File[] = [];
			let reqSize = 0;

			for (const file of files) {
				if (reqFiles.length > 0 && reqSize + file.size > reqLimit) {
					images = images.concat(await this.sendReq(reqFiles, null, category, stmtVars));
					reqFiles = [];
					reqSize = 0;
				}

				if (file.size > reqLimit) {
					images = images.concat(await this.sendReq([file], null, category, stmtVars));
				} else {
					reqFiles.push(file);
					reqSize += file.size;
				}
			}

			if (reqFiles.length > 0) {
				images = images.concat(await this.sendReq(reqFiles, null, category, stmtVars));
			}
		}

		return images.map(
			(image: any) =>
				new FileObj(Object.assign({ src: this.imagePipe.transform(image.img_dir + image.img_file) }, image)),
		);
	}

	private async sendReq(
		files: File[],
		url: string | null,
		category: number,
		stmtVars: { [key: string]: any },
	): Promise<any[]> {
		const response = await this.service.post(
			"images",
			{ files, urls: url ? [url] : [], img_catid: category, img: "", img_alt: "", stmtVars: stmtVars },
			undefined,
			true,
		);

		if (!response.success) {
			throw { error: response };
		}

		return response.images;
	}
}

class FileApiAdapter implements IApiAdapter {
	service: Service;
	fileService: Service;

	constructor(rest: RestService) {
		this.service = rest.init("statement");
		this.fileService = rest.init("crud/file");
	}

	async getCategories(categories?: Set<number>): Promise<Category[]> {
		const params = categories ? { file_catids: iter(categories.keys()).toArray() } : {};
		const response = await this.service.post("GetFileCats", params);
		return response.results.map((row: any) => new Category(row.file_catid, row.file_cat));
	}

	async upload(files: any[], url: string | null, category: number, stmtVars: { [key: string]: any }): Promise<any> {
		const ret = [];

		for (const file of files) {
			ret.push(
				this.fileService.post("upload", { file_catid: category, file: file, stmtVars: stmtVars }, null, true),
			);
		}

		if (url) {
			ret.push(
				this.fileService.post(
					"upload",
					{ file_catid: category, file_url: url, stmtVars: stmtVars },
					null,
					true,
				),
			);
		}

		return (await Promise.all(ret)).map((res) => new FileObj(res.file));
	}
}

class Category {
	constructor(public id: number, public name: string) {}
}

class FileObj {
	obj: any;
	selected: boolean = true;

	constructor(obj: any) {
		this.obj = obj;
	}
}
