import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "@core/app/services/user.service";
import { IPageData } from "@model/page-data";
import { combineLatest, Subject } from "rxjs";
import { distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SearchService {
	loadS: Subject<void> = new Subject();
	autoCompleteOptions = {
		disabled: false,
		focusFirst: false,
		focus: () => this.loadS.next(),
		changed: (model: any, event: any) => {
			if (event && (event.keyCode === 13 || event.isUserInput)) {
				if (model && model.hasOwnProperty("link")) {
					this.router.navigateByUrl(model.link);
				} else if (model && model.hasOwnProperty("item")) {
					this.router.navigateByUrl(model.item.link);
				} else if (model.length > 0) {
					this.router.navigateByUrl("/search?s=" + model);
				}
			} else if (model && (model.hasOwnProperty("link") || model.hasOwnProperty("item"))) {
				if (model.link) {
					this.router.navigateByUrl(model.link);
				} else if (model.item && model.item.link) {
					this.router.navigateByUrl(model.item.link);
				}
			}
		},
		enterKeySubmit: true,
		placeholder: "Search...",
	};
	searchValues$ = combineLatest([this.userService.loggedIn$, this.userService.isCustomer$, this.loadS]).pipe(
		distinctUntilChanged(),
		map(([loggedIn, isCustomer]) =>
			loggedIn && !isCustomer
				? this.pageData.searchUrls.salesSearchResults + "?cacheBuster=" + Math.random()
				: this.pageData.searchUrls.searchResults + "?cacheBuster=" + Math.random(),
		),
		switchMap((url) => this.http.get(url)),
		shareReplay(1),
	);

	constructor(
		private router: Router,
		private userService: UserService,
		private http: HttpClient,
		@Inject("PAGE_DATA") private pageData: IPageData,
	) {}
}
