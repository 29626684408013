<div class="filter-column">
	<div class="row">
		<div class="col w-full">
			<cm-search
				[searchString]="value"
				[searchFn]="doTypeaheadSearch"
				(result)="search($event)"
				[focusFirst]="true"
			></cm-search>
		</div>
	</div>
</div>
