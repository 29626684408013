<div class="cm-quick-bar">
	<div class="share addthis_share" [ngClass]="{ active: shareButtonsActive }">
		<div class="addthis_share_button" *ngFor="let btn of shareButtons">
			<div class="share-button {{ btn.service }}" (click)="share(btn)">
				<fa-icon [icon]="btn.icon" [fixedWidth]="btn.iconFixedWidth"></fa-icon>
			</div>
		</div>
	</div>
	<div class="cm-button-grid d-print-none">
		<ng-container *ngFor="let options of buttons">
			<div
				*ngIf="options.active || !options.hasOwnProperty('active')"
				class="quick-button"
				[ngStyle]="buttonStyle"
			>
				<a
					*ngIf="options.link && options.link.indexOf('tel:') === 0"
					mat-raised-button
					href="{{ options.link }}"
					target="{{ options && options.target ? options.target : '_self' }}"
					rel="noopener {{ options && options.rel ? options.rel : '' }}"
					class="px-0 {{ options && options.css ? options.css.classes : '' }}"
				>
					<fa-icon *ngIf="options && options.icon" [icon]="options.icon"></fa-icon>
					<span *ngIf="options && options.text" class="text" [innerHTML]="options.text"></span>
				</a>
				<a
					*ngIf="options.link && options.link.indexOf('tel:') === -1"
					mat-raised-button
					routerLink="{{ options.link }}"
					target="{{ options && options.target ? options.target : '_self' }}"
					rel="noopener {{ options && options.rel ? options.rel : '' }}"
					class="px-0 {{ options && options.css ? options.css.classes : '' }}"
				>
					<fa-icon *ngIf="options && options.icon" [icon]="options.icon"></fa-icon>
					<span *ngIf="options && options.text" class="text" [innerHTML]="options.text"></span>
				</a>
				<div
					mat-raised-button
					color="primary"
					class="{{ options && options.css ? options.css.classes : '' }}"
					*ngIf="!options.link"
					(click)="options.clicked()"
				>
					<fa-icon *ngIf="options && options.icon" [icon]="options.icon"></fa-icon>
					<span *ngIf="options && options.text" class="text" [innerHTML]="options.text"></span>
				</div>
			</div>
		</ng-container>
	</div>
</div>
