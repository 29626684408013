<div class="promotions-holder animate flex align-items-end" *ngIf="!mobile && totalCount > 0">
	<ng-container *ngFor="let promotion of promotions">
		<div *ngIf="!promotion.hide" class="single-promotion">
			<div class="theme-highlight promotion-heading flex" role="button" (click)="toggleVisible(promotion)">
				<cm-image2 [image]="promotion.iconImg"></cm-image2>
				<div class="flex-grow ml-2 mr-3">{{ promotion.promotion }}</div>
				<fa-icon [icon]="promotion.opened ? faMinus : faPlus" class="mr-3"></fa-icon>
			</div>
			<div class="promotion-body funky-show-hide flex flex-col" *ngIf="promotion.opened">
				<div>
					<cm-image2 [image]="promotion.mainImg"></cm-image2>
				</div>
				<div class="text-center mt-2">
					{{ promotion.content }}
				</div>
				<div class="text-center mt-2 mb-3">
					<a
						routerLink="{{ promotion.button.link }}"
						(click)="toggleVisible(promotion)"
						class="{{ promotion.button.css.classes }}"
						>{{ promotion.button.text }}</a
					>
				</div>
			</div>
		</div>
	</ng-container>
</div>
<ng-template #promotionPopup let-modal>
	<div class="modal-header theme-highlight">
		<h4 class="modal-title theme-highlight" id="promotionPopup">{{ promotions[0].title }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div>
			<cm-image2 [image]="promotions[0].popupImg"></cm-image2>
		</div>
		<div class="text-center mt-2">
			{{ promotions[0].desc }}
		</div>
		<div class="text-center mt-2 mb-3">
			<a
				routerLink="{{ promotions[0].button.link }}"
				(click)="modal.dismiss()"
				class="{{ promotions[0].button.css.classes }}"
				>{{ promotions[0].button.text }}</a
			>
		</div>
	</div>
</ng-template>
