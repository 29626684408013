import { Directive, Input, ViewContainerRef, TemplateRef, HostBinding, Inject, Component } from "@angular/core";
import { Path } from "shared/common";

@Component({
	selector: "img[cmImg]",
	template: "",
	styles: [
		`
			:host {
				object-fit: contain;
			}
		`,
	],
})
export class ImgDirective {
	@Input() cmImg!: ImgObj;
	@Input() size?: string;

	@HostBinding("alt") get alt() {
		return this.cmImg.img_alt;
	}

	@HostBinding("src") get src() {
		return (
			this.cmImg.img_dir &&
			this.cmImg.img_file &&
			this.insertSize(this.cmImg.img_dir + this.cmImg.img_file, this.size)
		);
	}

	@HostBinding("width") get width() {
		return this.cmImg.img_width;
	}

	@HostBinding("height") get height() {
		return this.cmImg.img_height;
	}

	constructor(@Inject("PAGE_DATA") private pageData: any) {}

	// TODO: set default format to "0,0" once it's supported
	private insertSize(value: string, size?: string): string {
		const dashSize = size ? `-${size}` : "";
		const path = new Path(value);
		const ext = path
			.extension()
			.map((ext) => `.${ext}`)
			.unwrapOr("");
		const fileStem = path.fileStem().unwrapOr(null);
		if (fileStem) {
			return `${this.pageData.appInfo.data.cdnRootPath}${path.parent().unwrap()}/${fileStem}${dashSize}${ext}`;
		} else {
			return "";
		}
	}
}

interface ImgObj {
	img_alt?: string | null;
	img_file?: string | null;
	img_dir?: string | null;
	img_height?: number | null;
	img_width?: number | null;
}
