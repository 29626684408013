import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { RvFilterState, RvInventoryService } from "@core/app/inventory/services/rv-inventory.service";
import { UserService } from "@core/app/services/user.service";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { faBars, faCaretDown, faCogs, faHeart, faSignOut, faTimes, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { FilterImageStyle } from "@model/search-filter-option";
import { merge, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, filter, first, map, startWith, takeUntil } from "rxjs/operators";
import { SearchService } from "../search/services/search.service";
import { MenuHelperService } from "../services/menu-helper.service";

@Component({
	selector: "cm-menu",
	templateUrl: "./menu.component.html",
	styleUrls: ["./menu.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnDestroy {
	@HostBinding("itemscope")
	get itemscope() {
		return "";
	}

	@HostBinding("itemtype")
	get itemtype() {
		return "http://schema.org/SiteNavigationElement";
	}

	menuData = this.menuHelperService.menu$;
	faBars = faBars;
	faTimes = faTimes;
	faHeart = faHeart;
	faUsers = faUsers;
	faCogs = faCogs;
	faSignOut = faSignOut;
	faCaretDown = faCaretDown;

	toggleMenuS: Subject<boolean> = new Subject();

	menuShow$ = merge(
		this.toggleMenuS,
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => false),
		),
	).pipe(startWith(false), distinctUntilChanged());

	newFilter: RvFilterState = this.inventory.createState();
	usedFilter: RvFilterState = this.inventory.createState();
	clearanceFilter: RvFilterState = this.inventory.createState();
	filterSettings = [
		{ field: "vehicle_type", label: "Type", imageStyle: FilterImageStyle.Show },
		{ field: "brand", label: "Brand", imageStyle: FilterImageStyle.OnHover },
		{ field: "price", label: "Price Range" },
	];

	ngOnDestroyRS: ReplaySubject<void> = new ReplaySubject();

	constructor(
		public userService: UserService,
		public viewport: ViewportService,
		public searchService: SearchService,
		private inventory: RvInventoryService,
		private menuHelperService: MenuHelperService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
		renderer: Renderer2,
	) {
		this.menuShow$.pipe(takeUntil(this.ngOnDestroyRS)).subscribe((menuShow) => {
			if (menuShow) {
				renderer.addClass(this.document.body, "overflow-hidden");
			} else {
				renderer.removeClass(this.document.body, "overflow-hidden");
			}
		});
		this.newFilter.setAddFilters({ condition: "new" });
		this.usedFilter.setAddFilters({ condition: "used" });
		this.clearanceFilter.setAddFilters({ promotion: "clearance" });
	}

	ngOnDestroy(): void {
		this.ngOnDestroyRS.next();
		this.ngOnDestroyRS.complete();
	}

	itemClick(event: any, item: any): void {
		console.log("itemClick", item);
		this.viewport.bp.down.md$.pipe(first()).subscribe((mobile) => {
			if (mobile) {
				if ((item.items && item.items.length > 0) || item.items$) {
					event.preventDefault();
					item.visible = !item.visible;
				} else {
					if (item.hasOwnProperty("clicked")) {
						item.clicked(event, item);
					}

					this.toggleMenuS.next(false);
				}
			} else {
				if (item.clicked) {
					item.clicked(event, item);
				}
				if (!item.link || item.link === "/#" || item.link === "#") {
					event.preventDefault();
					event.stopPropagation();
				}
			}
		});
	}

	showDropdown(menuItem: any, show: boolean): void {
		menuItem.showDropdown = show;
	}

	logout() {
		this.userService.logOut();
	}
}
