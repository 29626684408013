import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { SearchComponent } from "./components/search/search.component";

@NgModule({
	declarations: [SearchComponent],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule,
	],
	exports: [SearchComponent],
})
export class SearchModule {}
