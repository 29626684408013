import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { UserService } from "@core/app/services/user.service";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, ReplaySubject } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
let nextIdNum = 0;
@Component({
	selector: "cm-promotion-banner[bannerCat]",
	template: `
		<ng-container *ngFor="let obj of objs$ | async">
			<a *ngIf="!obj.external" [routerLink]="obj.link">
				<img [cmImg]="obj.orig" [size]="size" class="img-fluid" />
			</a>
			<a *ngIf="obj.external" [href]="obj.link" target="_blank">
				<img [cmImg]="obj.orig" [size]="size" class="img-fluid" />
			</a>
		</ng-container>
		<button
			mat-raised-button
			color="warn"
			*ngIf="(changeBanners$ | async) && bannerid"
			class="bannerChange"
			(click)="onImageUploadClick()"
		>
			Change Banner
		</button>
		<ng-template #modalImageUpload let-modal>
			<cm-modal-file-upload
				mode="image"
				[categoryFilter]="[5]"
				(close)="modal.close($event)"
				(dismiss)="modal.dismiss()"
			></cm-modal-file-upload>
		</ng-template>
	`,
	styles: [
		`
			:host {
				position: relative;
				display: block;
				text-align: center;
				align-items: center;
				justify-content: center;
			}
			:host ::ng-deep img {
				width: 100%;
			}
			.bannerChange {
				position: absolute;
				top: 0;
				left: 0;
			}
		`,
	],
})
export class PromotionBannerComponent implements OnChanges {
	@Input() bannerCat!: string;
	@Input() size: string = "o";
	@Input() disableLazyLoad: boolean = false;
	matDialogRef!: MatDialogRef<ImageUploadModal>;
	bannerCatSubj: ReplaySubject<string> = new ReplaySubject(1);
	refreshSubject: BehaviorSubject<undefined> = new BehaviorSubject(undefined);
	bannerid: string | null = null;
	idNum: number = 0;
	changeBanners$ = this.userService.permissions$.pipe(map((perms) => perms.hasPermission(["banners", "change"])));
	uploadedFiles: FileObj[] = [];

	objs$ = combineLatest(this.bannerCatSubj, this.refreshSubject).pipe(
		filter(([bannerCat, _]) => !!bannerCat),
		switchMap(([bannerCat, _]) => {
			const vars = { banner_cat: bannerCat, default_bannerid: bannerCat === "PromoTop" ? 727 : null };
			return this.transfer.transfer$("GetPromotionBanner:" + JSON.stringify(vars), () =>
				this.http
					.post("/api/statement/GetPromotionBanner", { vars })
					.pipe(map((response: any) => response.results)),
			);
		}),
		map((results) => {
			const objs = [];
			let promoFound = false;
			for (const banner of results!) {
				if (banner.promotion_bannerid) {
					promoFound = true;
				} else if (promoFound) {
					break;
				} else {
					this.bannerid = banner.bannerid;
				}

				objs.push({
					orig: banner,
					alt: banner.img_alt,
					title: banner.img || banner.img_alt,
					link: banner.link,
					external: banner.link && banner.link.indexOf("http") != -1,
				});
			}
			return objs;
		}),
	);

	constructor(
		public userService: UserService,
		private toastrService: ToastrService,
		private transfer: TransferRxService,
		private http: HttpClient,
		private matDialog: MatDialog,
	) {
		this.idNum = nextIdNum++;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.bannerCat) {
			this.bannerCatSubj.next(changes.bannerCat.currentValue);
		}
	}

	onImageUploadClick() {
		this.matDialog
			.open(ImageUploadModal)
			.afterClosed()
			.subscribe((result) => {
				if (result && result[0]) {
					this.http
						.post("/api/statement/UpdateBannerImg", {
							vars: { imgid: result[0].imgid, bannerid: this.bannerid },
						})
						.subscribe(
							() => {
								this.toastrService.success("Banner Updated");
								this.refreshSubject.next(undefined);
							},
							(error: any) => {
								this.toastrService.error(error.message);
							},
						);
				}
			});
	}
}

@Component({
	selector: "image-upload-modal",
	template: `
		<div class="md:w-800 w-500 max-w-full">
			<cm-modal-file-upload
				mode="image"
				[categoryFilter]="[5]"
				(close)="dialogRef.close($event)"
				(dismiss)="dialogRef.close(null)"
			></cm-modal-file-upload>
		</div>
	`,
})
export class ImageUploadModal {
	prefill: any = null;

	constructor(public dialogRef: MatDialogRef<ImageUploadModal>) {}
}

class FileObj {
	obj: any;
	selected: boolean = true;

	constructor(obj: any) {
		this.obj = obj;
	}
}
