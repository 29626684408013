import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlMatcher, UrlMatchResult, UrlSegment } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RootComponent } from "@core/app/pages/root/root.component";
import { RouteResolver } from "@core/app/services/route-resolver.service";
import { environment } from "@core/environments/environment";
import "hammerjs";
import { ToastNoAnimationModule } from "ngx-toastr";
import { CoreModule } from "./core.module";
import { PAGE_DATA } from "./generated/page-data";
import { UtilModule } from "./util/util.module";
import { PrerenderModule } from "shared";

export const routes: Routes = [
	{
		matcher: routeMatcher1 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-rv-showroom/page-rv-showroom.module").then((m) => m.PageRvShowroomModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "RvShowroom",
			pageTemplateId: 12,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-showroom\\/)all(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2&all=0",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher2 as UrlMatcher,
		loadChildren: () => import("./pages/page-listing/page-listing.module").then((m) => m.PageListingModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Listing",
			pageTemplateId: 46,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^\\/used-rvs\\/l-([0-9]+)(?:\\?.*)?$",
			rewriteTo: "listingid=1",
			stmtid: 107,
		},
	},
	{
		matcher: routeMatcher3 as UrlMatcher,
		loadChildren: () => import("./pages/page-condition/page-condition.module").then((m) => m.PageConditionModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Condition",
			pageTemplateId: 23,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/(travel-trailer-toy-hauler|diesel-class-c-motorhome|diesel-class-a-motorhome|gas-class-c-motorhome|gas-class-a-motorhome|5th-wheel-toy-hauler|class-b-motorhome|super-c-motorhome|travel-trailer|hybrid-trailer|folding-pop-up|other-vehicle|truck-camper|park-trailer|5th-wheel|unknown|diesel|gas|car|car-suv|car-sedan|car-coupe|crossover|car-minivan|truck|utility-vehicle)s?)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&vehicle_type=2&page=3",
			stmtid: 93,
		},
	},
	{
		matcher: routeMatcher4 as UrlMatcher,
		loadChildren: () => import("./pages/page-admin/page-admin.module").then((m) => m.PageAdminModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Admin",
			pageTemplateId: 88,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/new-admin).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher5 as UrlMatcher,
		loadChildren: () => import("./pages/page-msrp-sheet/page-msrp-sheet.module").then((m) => m.PageMsrpSheetModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "MsrpSheet",
			pageTemplateId: 41,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/(?:[^\\/]+)\\/(?:[^\\/]+)\\/l-([0-9]+))\\/msrp(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&listingid=2&msrp=0",
			stmtid: 107,
		},
	},
	{
		matcher: routeMatcher6 as UrlMatcher,
		loadChildren: () => import("./pages/page-condition/page-condition.module").then((m) => m.PageConditionModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Condition",
			pageTemplateId: 31,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/([^\\/]+)-rv-sale)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&promotion=2&page=3",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher7 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-invoice-sheet/page-invoice-sheet.module").then((m) => m.PageInvoiceSheetModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "InvoiceSheet",
			pageTemplateId: 43,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/(?:[^\\/]+)\\/(?:[^\\/]+)\\/l-([0-9]+))\\/invoice(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&listingid=2&invoice=0",
			stmtid: 107,
		},
	},
	{
		matcher: routeMatcher8 as UrlMatcher,
		loadChildren: () => import("./pages/page-favorites/page-favorites.module").then((m) => m.PageFavoritesModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Favorites",
			pageTemplateId: 38,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/your-favorite-rvs)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher9 as UrlMatcher,
		loadChildren: () => import("./pages/page-condition/page-condition.module").then((m) => m.PageConditionModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Condition",
			pageTemplateId: 7,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/([^\\/]+)-rvs)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&condition=2&page=3",
			stmtid: 93,
		},
	},
	{
		matcher: routeMatcher10 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-custom-build/page-custom-build.module").then((m) => m.PageCustomBuildModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "CustomBuild",
			pageTemplateId: 27,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/custom-build)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher11 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-manufacturer/page-manufacturer.module").then((m) => m.PageManufacturerModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Manufacturer",
			pageTemplateId: 5,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^\\/dealer\\/([^\\/]+)-rv(?:\\?.*)?$",
			rewriteTo: "mfr=1",
			stmtid: 93,
		},
	},
	{
		matcher: routeMatcher12 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-rv-showroom/page-rv-showroom.module").then((m) => m.PageRvShowroomModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "RvShowroom",
			pageTemplateId: 12,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-showroom)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher13 as UrlMatcher,
		loadChildren: () => import("./pages/page-crm/page-crm.module").then((m) => m.PageCrmModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Crm",
			pageTemplateId: 42,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/lead-management)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher14 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-job-posting/page-job-posting.module").then((m) => m.PageJobPostingModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "JobPosting",
			pageTemplateId: 87,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/jobs\\/application)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher15 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-job-posting/page-job-posting.module").then((m) => m.PageJobPostingModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "JobPosting",
			pageTemplateId: 86,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(?:(\\/jobs\\/j-([0-9]+)))(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&job_postingid=2",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher16 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-job-posting/page-job-posting.module").then((m) => m.PageJobPostingModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "JobPosting",
			pageTemplateId: 85,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/jobs)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher17 as UrlMatcher,
		loadChildren: () => import("./pages/page-account/page-account.module").then((m) => m.PageAccountModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Account",
			pageTemplateId: 60,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/my-account)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher18 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "About",
			pageTemplateId: 61,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/about-us)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher19 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-listing-thank-you/page-listing-thank-you.module").then(
				(m) => m.PageListingThankYouModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "ListingThankYou",
			pageTemplateId: 84,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^\\/deal\\/(\\d+)-([\\da-z]+)$",
			rewriteTo: "inquiryid=1&validation_key=2",
			stmtid: 2406,
		},
	},
	{
		matcher: routeMatcher20 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 126,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-reviews)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher21 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 125,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-service)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher22 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 123,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/floor-plan-types)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher23 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 122,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-payment-calculator)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher24 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 121,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-finance)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher25 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 120,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-delivery-services)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher26 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 134,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/dealer-brands)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher27 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 114,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/dealer)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher28 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 104,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/contact)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher29 as UrlMatcher,
		loadChildren: () => import("./pages/page-home-fns/page-home-fns.module").then((m) => m.PageHomeFnsModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Home",
			pageTemplateId: 39,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/)(\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher30 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 26,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-credit-application)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher31 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 29,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rv-trade-in-form)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher32 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 135,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/survey)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher33 as UrlMatcher,
		loadChildren: () => import("./pages/page-brand/page-brand.module").then((m) => m.PageBrandModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Brand",
			pageTemplateId: 1,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/(aliner|landmaster-pro-series|sprinter-campfire-edition|vengeance-rogue-armored|catalina-legacy-edition|catalina-summit-series|catalina-trail-blazer|catalina-destination|landmaster|catalina-expedition|passport-gt-series|passport-sl-series|spirit-ultra-lite|arctic-wolf-sr-1|cardinal-limited|synergy-sprinter|sprinter-limited|chaparral-x-lite|cardinal-luxury|springdale-mini|vengeance-rogue|surveyor-luxury|surveyor-legend|designer-legacy|siesta-sprinter|surveyor-grand|shadow-cruiser|chaparral-lite|holiday-house|no-boundaries|micro-minnie|catalina-sbx|impression|minnie-plus|minnie-drop|arctic-wolf|spirit-xtr|alpha-wolf|freelander|brookstone|springdale|milestone|wolf-den|wolf-pack|grey-wolf|chaparral|cherokee|wolf-pup|quantum|prodigy|admiral|stryker|prowler|voyage|spyder|minnie|carbon|shasta-i-5|shasta|raptor|sabre|r-pod|hike-200|hike|vibe|ace|scout|access|eagle|eagle-ht|pinnacle|north-point|seismic|seismic-luxury|jay-flight-destination|jay-flight-slx|jay-flight|jay-feather|jay-feather-micro|northern-spirit|northern-spirit-xtr|della-terra|della-terra-le|white-hawk))(?!\\/\\d{2,})(?:\\/([0-9]))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&brand=2&page=3",
			stmtid: 93,
		},
	},
	{
		matcher: routeMatcher34 as UrlMatcher,
		loadChildren: () => import("./pages/page-model/page-model.module").then((m) => m.PageModelModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Model",
			pageTemplateId: 4,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/(aliner|landmaster-pro-series|sprinter-campfire-edition|vengeance-rogue-armored|catalina-legacy-edition|catalina-summit-series|catalina-trail-blazer|catalina-destination|landmaster|catalina-expedition|passport-gt-series|passport-sl-series|spirit-ultra-lite|arctic-wolf-sr-1|cardinal-limited|synergy-sprinter|sprinter-limited|chaparral-x-lite|cardinal-luxury|springdale-mini|vengeance-rogue|surveyor-luxury|surveyor-legend|designer-legacy|siesta-sprinter|surveyor-grand|shadow-cruiser|chaparral-lite|holiday-house|no-boundaries|micro-minnie|catalina-sbx|impression|minnie-plus|minnie-drop|arctic-wolf|spirit-xtr|alpha-wolf|freelander|brookstone|springdale|milestone|wolf-den|wolf-pack|grey-wolf|chaparral|cherokee|wolf-pup|quantum|prodigy|admiral|stryker|prowler|voyage|spyder|minnie|carbon|shasta-i-5|shasta|raptor|sabre|r-pod|hike-200|hike|vibe|ace|scout|access|eagle|eagle-ht|pinnacle|north-point|seismic|seismic-luxury|jay-flight-destination|jay-flight-slx|jay-flight|jay-feather|jay-feather-micro|northern-spirit|northern-spirit-xtr|della-terra|della-terra-le|white-hawk)\\/([^\\/]+))(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&brand=2&model_num=3&page=4",
			stmtid: 107,
		},
	},
	{
		matcher: routeMatcher35 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 183,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/customer-check-in)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher37 as UrlMatcher,
		loadChildren: () => import("./pages/page-listing/page-listing.module").then((m) => m.PageListingModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Listing",
			pageTemplateId: 3,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/(?:[^\\/]+)\\/(?:[^\\/]+)\\/l-([0-9]+))(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&listingid=2",
			stmtid: 107,
		},
	},
	{
		matcher: routeMatcher38 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 246,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/parts-request)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher42 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 247,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/warranty-request)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher39 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 478,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/service-request)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher40 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 248,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/schedule-a-visit)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher43 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 435,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/rentals)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher41 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 13,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/.+?)(?:(\\/[0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
];

export function routeMatcher1(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-showroom\/)all(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher2(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^\/used-rvs\/l-([0-9]+)(?:\?.*)?$/, segments, false);
}
export function routeMatcher3(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(
		/^(\/(travel-trailer-toy-hauler|diesel-class-c-motorhome|diesel-class-a-motorhome|gas-class-c-motorhome|gas-class-a-motorhome|5th-wheel-toy-hauler|class-b-motorhome|super-c-motorhome|travel-trailer|hybrid-trailer|folding-pop-up|other-vehicle|truck-camper|park-trailer|5th-wheel|unknown|diesel|gas|car|car-suv|car-sedan|car-coupe|crossover|car-minivan|truck|utility-vehicle)s?)(?:\/([0-9]+))?(?:\?.*)?$/,
		segments,
		false,
	);
}
export function routeMatcher4(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/new-admin).*$/, segments, true);
}
export function routeMatcher5(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/(?:[^\/]+)\/(?:[^\/]+)\/l-([0-9]+))\/msrp(?:\?.*)?$/, segments, false);
}
export function routeMatcher6(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/([^\/]+)-rv-sale)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher7(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/(?:[^\/]+)\/(?:[^\/]+)\/l-([0-9]+))\/invoice(?:\?.*)?$/, segments, false);
}
export function routeMatcher8(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/your-favorite-rvs)(?:\?.*)?$/, segments, false);
}
export function routeMatcher9(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/([^\/]+)-rvs)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher10(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/custom-build)(?:\?.*)?$/, segments, false);
}
export function routeMatcher11(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^\/dealer\/([^\/]+)-rv(?:\?.*)?$/, segments, false);
}
export function routeMatcher12(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-showroom)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher13(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/lead-management)$/, segments, false);
}
export function routeMatcher14(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/jobs\/application)(?:\?.*)?$/, segments, false);
}
export function routeMatcher15(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(?:(\/jobs\/j-([0-9]+)))(?:\?.*)?$/, segments, false);
}
export function routeMatcher16(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/jobs)(?:\?.*)?$/, segments, false);
}
export function routeMatcher17(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/my-account)(?:\?.*)?$/, segments, false);
}
export function routeMatcher18(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/about-us)$/, segments, false);
}
export function routeMatcher19(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^\/deal\/(\d+)-([\da-z]+)$/, segments, false);
}
export function routeMatcher20(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-reviews)(?:\?.*)?$/, segments, false);
}
export function routeMatcher21(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-service)(?:\?.*)?$/, segments, false);
}
export function routeMatcher22(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/floor-plan-types)(?:\?.*)?$/, segments, false);
}
export function routeMatcher23(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-payment-calculator)(?:\?.*)?$/, segments, false);
}
export function routeMatcher24(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-finance)(?:\?.*)?$/, segments, false);
}
export function routeMatcher25(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-delivery-services)(?:\?.*)?$/, segments, false);
}
export function routeMatcher26(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/dealer-brands)(?:\?.*)?$/, segments, false);
}
export function routeMatcher27(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/dealer)(?:\?.*)?$/, segments, false);
}
export function routeMatcher28(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/contact)(?:\?.*)?$/, segments, false);
}
export function routeMatcher29(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/)(\?.*)?$/, segments, false);
}
export function routeMatcher30(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-credit-application)(?:\?.*)?$/, segments, false);
}
export function routeMatcher31(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rv-trade-in-form)(?:\?.*)?$/, segments, false);
}
export function routeMatcher32(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/survey)(?:\?.*)?$/, segments, false);
}
export function routeMatcher33(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(
		/^(\/(aliner|landmaster-pro-series|sprinter-campfire-edition|vengeance-rogue-armored|catalina-legacy-edition|catalina-summit-series|catalina-trail-blazer|catalina-destination|landmaster|catalina-expedition|passport-gt-series|passport-sl-series|spirit-ultra-lite|arctic-wolf-sr-1|cardinal-limited|synergy-sprinter|sprinter-limited|chaparral-x-lite|cardinal-luxury|springdale-mini|vengeance-rogue|surveyor-luxury|surveyor-legend|designer-legacy|siesta-sprinter|surveyor-grand|shadow-cruiser|chaparral-lite|holiday-house|no-boundaries|micro-minnie|catalina-sbx|impression|minnie-plus|minnie-drop|arctic-wolf|spirit-xtr|alpha-wolf|freelander|brookstone|springdale|milestone|wolf-den|wolf-pack|grey-wolf|chaparral|cherokee|wolf-pup|quantum|prodigy|admiral|stryker|prowler|voyage|spyder|minnie|carbon|shasta-i-5|shasta|raptor|sabre|r-pod|hike-200|hike|vibe|ace|scout|access|eagle|eagle-ht|pinnacle|north-point|seismic|seismic-luxury|jay-flight-destination|jay-flight-slx|jay-flight|jay-feather|jay-feather-micro|northern-spirit|northern-spirit-xtr|della-terra|della-terra-le|white-hawk))(?!\/\d{2,})(?:\/([0-9]))?(?:\?.*)?$/,
		segments,
		false,
	);
}
export function routeMatcher34(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(
		/^(\/(aliner|landmaster-pro-series|sprinter-campfire-edition|vengeance-rogue-armored|catalina-legacy-edition|catalina-summit-series|catalina-trail-blazer|catalina-destination|landmaster|catalina-expedition|passport-gt-series|passport-sl-series|spirit-ultra-lite|arctic-wolf-sr-1|cardinal-limited|synergy-sprinter|sprinter-limited|chaparral-x-lite|cardinal-luxury|springdale-mini|vengeance-rogue|surveyor-luxury|surveyor-legend|designer-legacy|siesta-sprinter|surveyor-grand|shadow-cruiser|chaparral-lite|holiday-house|no-boundaries|micro-minnie|catalina-sbx|impression|minnie-plus|minnie-drop|arctic-wolf|spirit-xtr|alpha-wolf|freelander|brookstone|springdale|milestone|wolf-den|wolf-pack|grey-wolf|chaparral|cherokee|wolf-pup|quantum|prodigy|admiral|stryker|prowler|voyage|spyder|minnie|carbon|shasta-i-5|shasta|raptor|sabre|r-pod|hike-200|hike|vibe|ace|scout|access|eagle|eagle-ht|pinnacle|north-point|seismic|seismic-luxury|jay-flight-destination|jay-flight-slx|jay-flight|jay-feather|jay-feather-micro|northern-spirit|northern-spirit-xtr|della-terra|della-terra-le|white-hawk)\/([^\/]+))(?:\/([0-9]+))?(?:\?.*)?$/,
		segments,
		false,
	);
}
export function routeMatcher35(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/customer-check-in)(?:\?.*)?$/, segments, false);
}
export function routeMatcher37(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/(?:[^\/]+)\/(?:[^\/]+)\/l-([0-9]+))(?:\?.*)?$/, segments, false);
}
export function routeMatcher38(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/parts-request)(?:\?.*)?$/, segments, false);
}
export function routeMatcher39(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/service-request)(?:\?.*)?$/, segments, false);
}
export function routeMatcher40(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/schedule-a-visit)(?:\?.*)?$/, segments, false);
}
export function routeMatcher42(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/warranty-request)(?:\?.*)?$/, segments, false);
}
export function routeMatcher41(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/.+?)(?:(\/[0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher43(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/rentals)(?:\?.*)?$/, segments, false);
}

export function routeMatcher(regex: RegExp, segments: UrlSegment[], hasChildRouter: boolean): UrlMatchResult | null {
	if (regex.test("/" + segments.map((x) => x.path).join("/"))) {
		if (hasChildRouter) {
			return { consumed: [segments[0]] };
		} else {
			return { consumed: segments };
		}
	} else {
		return null;
	}
}

@NgModule({
	declarations: [RootComponent],
	imports: [
		CommonModule,
		CoreModule.forRoot(),
		ToastNoAnimationModule.forRoot({ positionClass: "toast-top-center" }),
		RouterModule.forRoot(routes, {
			initialNavigation: "enabledBlocking",
			scrollPositionRestoration: "enabled",
			anchorScrolling: "enabled",
			onSameUrlNavigation: "reload",
		}),
		ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
		UtilModule,
		PrerenderModule,
	],
	providers: [{ provide: "PAGE_DATA", useValue: PAGE_DATA }],
	entryComponents: [RootComponent],
	bootstrap: [RootComponent],
})
export class AppModule {}
