<div *ngIf="showFilter$ | async" class="cm-filter-list">
	<div *ngIf="showButtons && buttonsAbove" class="container-fluid">
		<div class="row filter-buttons mb-2">
			<div class="col w-1/2 lg:w-full lg:hidden cm-toggle-filter">
				<button
					mat-raised-button
					class="filter-btn bg-transparent border border-solid border-secondary hover:bg-secondary hover:text-white"
					(click)="toggleFilterVisibilityForMobile(false)"
				>
					Apply Filter
				</button>
			</div>
			<div class="cm-filter-list-reset col w-1/2 lg:w-full lg:text-right">
				<button
					mat-raised-button
					class="filter-btn border border-solid border-secondary hover:bg-secondary hover:text-white"
					(click)="doResetFilters()"
				>
					Clear Filter
				</button>
			</div>
		</div>
	</div>

	<cdk-accordion multi class="accordion" [ngClass]="{ loading: state.loading$ | async }">
		<cdk-accordion-item
			*ngFor="let filter of state.usedFiltersOptions$ | async; index as index; trackBy: filterTrackByName"
			#accordionItem="cdkAccordionItem"
			class="accordion-item"
			[expanded]="
				(initiallyExpandedSections$ | async) && initialActivatedFilters.includes(filter.key) ? true : false
			"
		>
			<div class="accordion-item-header" [attr.id]="'accordion-header-' + index" (click)="accordionItem.toggle()">
				<span class="accordion-title" [innerHTML]="filter.label"></span>
				<span class="filter-toggle flex-between-center">
					<ng-container *ngIf="accordionItem.expanded; then collapse; else expand"></ng-container>
					<ng-template #collapse>
						<span class="mr-2">collapse</span>
						<fa-icon [icon]="faMinusCircle"></fa-icon>
					</ng-template>
					<ng-template #expand>
						<span class="mr-2">expand</span>
						<fa-icon [icon]="faPlusCircle"></fa-icon>
					</ng-template>
				</span>
			</div>
			<div
				class="p-4"
				role="region"
				[style.display]="accordionItem.expanded ? '' : 'none'"
				[attr.id]="'accordion-body-' + index"
				[attr.aria-labelledby]="'accordion-header-' + index"
			>
				<ng-container [ngSwitch]="filter.type">
					<cm-range-selector *ngSwitchCase="'range'" [range]="filter" (select)="doChange(filter, $event)">
					</cm-range-selector>
					<cm-range-input-selector
						*ngSwitchCase="'priceRange'"
						[range]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-range-input-selector>
					<cm-range-slider-selector
						*ngSwitchCase="'paymentRange'"
						[range]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-range-slider-selector>
					<cm-predictive-selector
						*ngSwitchCase="'predictive'"
						[filter]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-predictive-selector>
					<cm-checkbox-selector
						*ngSwitchCase="'checkbox'"
						[filter]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-checkbox-selector>
					<cm-keyword-selector
						*ngSwitchCase="'searchBox'"
						[filter]="filter"
						(select)="doChange(filter, $event)"
					>
					</cm-keyword-selector>
					<cm-select-selector *ngSwitchCase="'select'" [filter]="filter" (select)="doChange(filter, $event)">
					</cm-select-selector>
					<ng-container *ngSwitchDefault>{{ filter | json }}</ng-container>
				</ng-container>
			</div>
		</cdk-accordion-item>
	</cdk-accordion>

	<div *ngIf="showButtons && !buttonsAbove" class="container-fluid">
		<div class="row filter-buttons mb-2">
			<div class="col w-1/2 lg:w-full lg:hidden cm-toggle-filter">
				<button
					mat-raised-button
					class="filter-btn bg-transparent border border-solid border-secondary hover:bg-secondary hover:text-white"
					(click)="toggleFilterVisibilityForMobile(false)"
				>
					Apply Filter
				</button>
			</div>
			<div class="cm-filter-list-reset col w-1/2 lg:w-full lg:text-right">
				<button
					mat-raised-button
					class="filter-btn border border-solid border-secondary hover:bg-secondary hover:text-white"
					(click)="doResetFilters()"
				>
					Clear Filter
				</button>
			</div>
		</div>
	</div>
</div>
