import { DOCUMENT } from "@angular/common";
import { Injectable, Inject } from "@angular/core";
import { UserService } from "./user.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
	providedIn: "root",
})
export class GTMService {
	dataLayer = ((window as any).dataLayer = (window as any).dataLayer || []);

	constructor(
		private cookieService: CookieService,
		private userService: UserService,
		@Inject(DOCUMENT) private document: Document,
	) {}

	track(category: any, action: any, label: any, props?: any) {
		this.dataLayer.push({
			event: "ngTrackEvent",
			attributes: Object.assign(this.globalAttrs(category, action, label), props),
		});
	}

	trackEvent(event: any, props: any) {
		this.dataLayer.push({
			event: event,
			attributes: Object.assign(this.globalAttrs(null, null, null), props),
		});
	}

	private globalAttrs(category: any, action: any, label: any) {
		const route = (window as any).route;
		const statementResults = route && route.statement ? route.statement.results : null;

		return {
			category: category,
			action: action,
			label: label,
			pageType: route ? route.title : null,
			pageTitle: statementResults && statementResults[0] ? statementResults[0].title : this.document.title,
			authRole: this.userService.get("auth_role"),
			customerId: this.userService.get("customerId"),
			isEmployee: this.userService.loggedIn() && !this.userService.isCustomer(),
			isCustomer: this.userService.isCustomer(),
			isVisitor: !this.userService.loggedIn(),
			salesPersonId: this.userService.get("salespersonId"),
			salesPersonName: this.userService.get("user_display_name"),
			uid: this.cookieService.get("uid"),
			userId: this.userService.get("userid"),
			userLoggedIn: this.userService.loggedIn(),
			visitorId: this.cookieService.get("visitorId"),
			visitorVisitId: this.cookieService.get("visitorVisitId"),
		};
	}
}
