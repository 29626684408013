import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { SearchService } from "@core/app/search/services/search.service";
import { DealerService } from "@core/app/services/dealer.service";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";

@Component({
	selector: "cm-header-fns",
	template: `
		<header class="header">
			<div class="w-full cm-header-container px-5" itemscope itemtype="http://schema.org/WPHeader">
				<div class="row items-center h-full">
					<div
						class="logo col w-auto flex-initial py-2 lg:text-center"
						itemscope
						itemtype="http://schema.org/Organization"
					>
						<meta
							*ngIf="dealerService.logo$ | async as logo"
							itemprop="logo"
							[content]="logo | image : 'hl'"
						/>
						<meta itemprop="url" [content]="siteUrl" />
						<meta itemprop="name" [content]="dealer" />
						<a routerLink="/">
							<img
								[src]="dealerService.logo$ | async | image : 'hl'"
								width="{{ pageData.appInfo.data.siteLogo.width }}"
								height="{{ pageData.appInfo.data.siteLogo.height }}"
								class="img-fluid"
								[alt]="dealer"
							/>
						</a>
					</div>
					<div
						class="col flex items-center flex-row-reverse lg:flex-row flex-1 justify-start md:justify-between w-5/12 md:w-auto text-right px-1"
					>
						<cm-menu class="md:text-right lg:text-left"></cm-menu>
						<div class="md:flex-1 flex-center">
							<a class="text-primary font-bold mr-2" href="tel:{{ dealerService.phoneNumber$ | async }}"
								><span class="md:hidden phone-icon"><fa-icon [icon]="faPhoneAlt"></fa-icon></span
								><span class="hidden md:inline-block phone-number">{{
									dealerService.phoneNumber$ | async
								}}</span></a
							>
						</div>
					</div>
					<div class="custom-links col hidden lg:block">
						<div class="row justify-center items-start">
							<div class="col w-1/2 text-center py-2 px-1">
								<a routerLink="/contact" class="inline-block">
									<img
										src="https://dfm-cdn.com/static/15/iconcontact.png"
										class="inline"
										alt="Contact Us"
										width="18px"
										height="33px"
									/><br />
									Contact
								</a>
							</div>
							<div class="col w-1/2 text-center py-2 px-1">
								<a routerLink="/rv-service" class="inline-block">
									<img
										src="https://dfm-cdn.com/static/15/icon-serviceparts.png"
										class="inline"
										alt="Service"
										width="34px"
										height="34px"
									/><br />
									Service
								</a>
							</div>
						</div>
					</div>
					<div class="search-box hidden lg:block col col-auto px-2">
						<cm-autocomplete
							[searchItems]="searchService.searchValues$ | async"
							[options]="searchService.autoCompleteOptions"
							class="cmauto_1"
						></cm-autocomplete>
					</div>
					<div class="hidden lg:block col text-center px-2 social-icons">
						<cm-social-links2></cm-social-links2>
					</div>
				</div>
				<div class="row lg:hidden">
					<div class="col w-full">
						<cm-autocomplete
							[searchItems]="searchService.searchValues$ | async"
							[options]="searchService.autoCompleteOptions"
							class="cmauto_1 px-1 mobile"
						></cm-autocomplete>
					</div>
				</div>
			</div>
		</header>
	`,
	styles: [
		`
			header {
				position: sticky;
				background: #fff;
				top: 0;
				width: 100%;
				margin-bottom: 3px;
				z-index: 135;
				box-shadow: 0 2px 5px 0 rgba(51, 51, 51, 0.43);
				transition: top 0.3s ease-in-out;
			}
			.cm-header-container,
			.main {
				position: relative;
				height: 100%;
			}
			.logo img {
				height: 70px;
				padding: 0 15px;
				width: auto;
			}
			.social-icons {
				min-width: 154px;
				max-width: 154px;
			}
			.cm-header-container .material-icons {
				font-size: 30px !important;
			}
			.phone-icon {
				background: #fff;
				padding: 5px 10px;
				cursor: pointer;
				position: relative;
				right: 5px;
				font-size: 28px;
				line-height: 28px;
				z-index: 100;
			}
			.phone-number {
				cursor: pointer;
				font-size: 24px;
				line-height: 24px;
				z-index: 100;
			}
			@media (max-width: 1477px) {
				.social-icons {
					display: none !important;
				}
			}
			@media (max-width: 1506px) {
				.phone-number {
					font-size: 20px;
					line-height: 20px;
				}
			}
			@media (max-width: 1328px) {
				.phone-number {
					font-size: 18px;
					line-height: 18px;
				}
			}
			@media (max-width: 1293px) {
				.custom-links {
					display: none !important;
				}
			}
			@media (max-width: 1200px) {
				.search-box {
					display: none !important;
				}
			}
			@media (max-width: 1019px) {
				.phone-number {
					display: none !important;
				}
			}
			@media print {
				header {
					display: none;
					position: relative;
				}
			}
			::ng-deep .cmauto_1.mobile {
				.mat-autocomplete-panel.mat-autocomplete-visible {
					max-width: 75vw;
					text-wrap: wrap;
					overflow-wrap: break-word;
					word-break: break-word;
				}
				.mat-option {
					min-height: 48px;
					height: auto;
					white-space: normal;
				}
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderFnsComponent {
	siteUrl: string = "https://" + this.pageData.host + "/";
	dealer: string = this.pageData.appInfo.data.siteName;
	faPhoneAlt = faPhoneAlt;
	constructor(
		@Inject("PAGE_DATA") public pageData: IPageData,
		public searchService: SearchService,
		public dealerService: DealerService,
	) {}
}
