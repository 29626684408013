import { IEnvironment } from "./environment-types";
import { environment as environment23 } from "./environment.23";
import { environmentDefault } from "./environment.default";

export { IEnvironment } from "./environment-types";

export const environment: IEnvironment = {
	...environmentDefault,
	...environment23,
	production: true,
};
