<ng-container *ngFor="let f of filter.results; let index = index; trackBy: tracker">
	<div class="filter-row">
		<div class="filter-column">
			<div class="form-group form-check">
				<input
					type="checkbox"
					id="cm-cb-{{ filter.name }}-{{ index }}"
					class="form-check-input"
					[ngClass]="{ relative: images[f.id] && filter.imageStyle === FilterImageStyle.Above }"
					[(ngModel)]="boxes[f.id]"
					(ngModelChange)="doChange(f, $event)"
				/>
				<label for="cm-cb-{{ filter.name }}-{{ index }}" class="form-check-label">
					<span class="filter-text">
						{{ f.label }} {{ f.listing_count ? "(" + f.listing_count + ")" : "" }}
					</span>
				</label>
			</div>
		</div>
	</div>
</ng-container>
