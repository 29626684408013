// dependencies
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@core/app/shared/shared.module";
import { config } from "@fortawesome/fontawesome-svg-core";
import { EqualValidatorDirective } from "./directive/equal-validator.directive";
import { EditWidgetComponent } from "./edit-widget/edit-widget.component";
import { FooterModule } from "./footer/footer.module";
import { HeaderModule } from "./header/header.module";
import { ImageModule } from "./image/image.module";
import { PromotionPopupComponent } from "./promotion/components/promotion-popup/promotion-popup.component";
import { RouteResolver } from "./services/route-resolver.service";
import { SliderModule } from "./slider/slider.module";
import { UserWidgetComponent } from "./user-widget/user-widget.component";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
	declarations: [EqualValidatorDirective, PromotionPopupComponent, UserWidgetComponent, EditWidgetComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		RouterModule.forChild([]),
		SharedModule,
		SliderModule,
		FooterModule,
		ImageModule,
		HeaderModule,
		MatSelectModule,
	],
	exports: [
		EqualValidatorDirective,
		PromotionPopupComponent,
		SharedModule,
		UserWidgetComponent,
		SliderModule,
		HeaderModule,
		FooterModule,
		ImageModule,
		EditWidgetComponent,
	],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}
