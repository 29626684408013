import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Pagination2Component } from "./pagination2.component";

@NgModule({
	declarations: [Pagination2Component],
	imports: [CommonModule, RouterModule, FontAwesomeModule],
	exports: [Pagination2Component],
})
export class Pagination2Module {}
