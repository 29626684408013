import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { Observable, of } from "rxjs";
import { debounceTime, filter, map, tap } from "rxjs/operators";
import levenshtein from "fast-levenshtein";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { iter } from "@common/iter";

interface ISearchItem {
	text: string;
	link?: string;
	value?: string;
	weight?: number;
}

interface IButton {
	text: string;
	clicked: any; // function?
}

interface IOptions {
	buttons: IButton;
	changed: any; // function?
	disabled: boolean;
	enterKeySubmit: boolean;
	focus: any; // function?
	focusFirst: boolean;
	icon: string;
	placeholder: string;
}

let idNum = 0;

@Component({
	selector: "cm-autocomplete",
	template: `
		<div
			class="cm-autocomplete input-group theme-fg-before theme-border-before theme-fg-after theme-border-after cm-autocomplete-container"
			itemprop="potentialAction"
			itemscope
			itemtype="http://schema.org/SearchAction"
		>
			<meta itemprop="target" content="{{ host }}/search?s={s}" />
			<span class="input-group-addon" *ngIf="options && options.icon">
				<fa-icon [icon]="options.icon"></fa-icon>
			</span>
			<label class="sr-only" for="typeahead-{{ id }}">{{ options.placeholder }}</label>
			<input
				class="form-control cm-text-field theme-bg-as-fg theme-border"
				itemprop="query-input"
				type="text"
				name="s"
				id="typeahead-{{ id }}"
				[formControl]="searchControl"
				[placeholder]="options.placeholder"
				(mouseenter)="options.focus && options.focus()"
				[matAutocomplete]="auto"
			/>
			<mat-autocomplete #auto="matAutocomplete" panelWidth="auto" [autoActiveFirstOption]="true">
				<mat-option
					*ngFor="let item of searchItemsTemp | async"
					[value]="item.text"
					(onSelectionChange)="options.changed(item, $event); searchControl.setValue('')"
				>
					{{ item.text }}
				</mat-option>
			</mat-autocomplete>
		</div>
	`,
	styleUrls: ["./autocomplete.component.scss"],
})
export class AutocompleteComponent implements OnInit {
	id = idNum++;
	host = "";
	searching = false;
	faSpinner = faSpinner;
	searchControl = new FormControl();
	@Input() model: any;
	@Input() options!: IOptions;
	@Input() searchItems!: ISearchItem[];
	searchItemsTemp!: Observable<ISearchItem[]>;

	@ViewChild(MatAutocomplete) matAutocomplete!: MatAutocomplete;

	constructor(router: Router, @Inject(DOCUMENT) private document: Document) {
		router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.model = null;
			this.document.body.click();
		});
	}

	ngOnInit() {
		this.searchItemsTemp = this.searchControl.valueChanges.pipe(
			debounceTime(600),
			map((value) => this.search(value)),
			tap(() => this.matAutocomplete._keyManager.setFirstItemActive()),
		);
	}

	private search = (term: string) => {
		if (term.length < 2 || !this.searchItems) return [];
		const maxLen = iter(this.searchItems)
			.map((item) => item.text.length)
			.max()
			.unwrapOr(0);
		return this.searchItems
			.filter((v) =>
				term
					.toLowerCase()
					.split(" ")
					.some((x) => v.text.toLowerCase().includes(x)),
			)
			.sort((a, b) => {
				const weight = (b.weight || 0) - (a.weight || 0);
				if (weight !== 0) return weight;
				return (
					levenshtein.get(b.text.toLowerCase().padEnd(maxLen, " "), term.toLowerCase().padEnd(maxLen, " ")) -
					levenshtein.get(a.text.toLowerCase().padEnd(maxLen, " "), term.toLowerCase().padEnd(maxLen, " "))
				);
			});
	};
}
