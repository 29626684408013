import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { isPrerendering } from "shared";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(() => {
		if ("serviceWorker" in navigator && environment.production) {
			window.addEventListener("load", () => navigator.serviceWorker.register("ngsw-worker.js"));
		}
	})
	.catch((err) => console.error(err));

if (isPrerendering()) {
	document.body.style.display = "none";
}

caches.keys().then((names) => {
	for (let name of names) caches.delete(name);
});
