import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BgComponent } from "./components/bg.component";
import { TintComponent } from "./components/tint.component";
import { ReplaceLineBreaksPipe } from "../pipes/nl2br.pipe";

@NgModule({
	declarations: [BgComponent, TintComponent, ReplaceLineBreaksPipe],
	imports: [CommonModule],
	exports: [BgComponent, TintComponent, ReplaceLineBreaksPipe],
})
export class LayoutModule {}
