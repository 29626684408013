import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ImagePipe } from "@core/app/image/pipes/image.pipe";
import { CacheService } from "@core/app/services/cache.service";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { first, tap } from "rxjs/operators";
import { PromotionsService } from "../../services/promotions.service";

@Component({
	selector: "cm-promotion-popup",
	templateUrl: "./promotion-popup.component.html",
	styleUrls: ["./promotion-popup.component.scss"],
	providers: [ImagePipe],
})
export class PromotionPopupComponent implements OnDestroy, OnInit {
	faMinus = faMinus;
	faPlus = faPlus;
	mobile: boolean = false;
	totalCount: number = 0;
	promotions: any = [];
	sessionService: any = null;

	constructor(
		router: Router,
		cacheService: CacheService,
		private promotionsService: PromotionsService,
		private imagePipe: ImagePipe,
		@Inject(DOCUMENT) private document: any,
	) {
		this.sessionService = cacheService.init("sessionStorage");
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				for (const item of this.promotions) {
					if (item.opened) {
						item.opened = false;
					}
				}
			}
		});
	}

	handleWindowSizeClosure: () => void = () => this.handleWindowSize();

	handleWindowSize(): void {
		const winWidth = this.document.body.clientWidth;

		this.mobile = winWidth <= 991;

		if (this.mobile) {
			for (const item of this.promotions) {
				if (item.opened) {
					item.opened = false;
				}
			}
		}
	}

	hide(item: any): void {
		item.hide = true;
		this.totalCount--;
	}

	ngOnInit() {
		this.mobile = this.document.body.clientWidth <= 991;
		window.addEventListener("resize", this.handleWindowSizeClosure);
		this.promotionsService.promotions$
			.pipe(
				tap((response) => {
					if (response.success) {
						for (const item of response.results!) {
							this.totalCount++;
							const current = {
								hide: false,
								opened: false,
								promotion: item.promotion,
								desc: item.promotion_desc,
								title: item.promotion,
								headerClass: "theme-highlight",
								content: item.meta_desc,
								button: {
									text: "View Discounted RVs",
									link: item.content_page_url,
									css: {
										classes: "mat-raised-button mat-button-base mat-primary mat-focus-indicator",
									},
								},
								mainImg: {
									src: this.imagePipe.transform(item.meta_img_dir + item.meta_img_file, "s"),
									alt: item.promotion,
								},
								popupImg: {
									src: this.imagePipe.transform(item.meta_img_dir + item.meta_img_file, "l"),
									alt: item.promotion,
								},
								iconImg: {
									src: this.imagePipe.transform(item.img_dir + item.img_file, "i"),
									alt: item.promotion,
								},
							};
							this.promotions.push(current);
						}
					}
				}),
				first(),
			)
			.subscribe();
	}

	ngOnDestroy() {
		window.removeEventListener("resize", this.handleWindowSizeClosure);
	}

	toggleVisible(item: any): void {
		item.opened = !item.opened;
	}
}
