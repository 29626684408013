import { DOCUMENT } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { iterObj } from "@common/iter";
import { cloneDeep } from "@common/util";
import { faFacebook, faPinterest, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowCircleUp, faCopy, faPhone, faShareAlt, faStar, faSync, faTags } from "@fortawesome/pro-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { filter } from "rxjs/operators";
import { AppService } from "../services/app.service";
import { DealerService } from "../services/dealer.service";

declare var FB: any;
declare var PinUtils: any;

@Component({
	selector: "cm-quick-bar",
	templateUrl: "./quick-bar.component.html",
	styleUrls: ["./quick-bar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class QuickBarComponent {
	buttons: any[];
	initialButtons: any[];
	buttonStyle: any = {};
	shareButtons: any[];
	shareButtonsActive: boolean;

	constructor(
		public app: AppService,
		router: Router,
		@Inject(DOCUMENT) private document: any,
		@Inject("PAGE_DATA") pageData: any,
		private cd: ChangeDetectorRef,
		private toastr: ToastrService,
		private dealerService: DealerService,
	) {
		this.buttons = [
			{
				id: "new",
				text: "New",
				icon: faStar,
				iconFixedWidth: true,
				css: {
					classes: "btn text-white theme-bg w-full",
				},
				link: "/new-rvs",
				active: true,
			},
			{
				id: "used",
				text: "Used",
				icon: faSync,
				iconFixedWidth: true,
				css: {
					classes: "btn text-white theme-bg w-full",
				},
				link: "/used-rvs",
				active: true,
			},
			{
				id: "clearance",
				text: "Clearance",
				icon: faTags,
				iconFixedWidth: true,
				css: {
					classes: "btn text-white theme-bg w-full",
				},
				link: "/clearance-rv-sale",
				active: true,
			},
			{
				id: "call",
				text: "Call",
				css: {
					classes: "btn text-white theme-highlight w-full",
				},
				icon: faPhone,
				iconFixedWidth: true,
				link: this.dealerService.phoneNumber
					? "tel:" + this.dealerService.phoneNumber.replace(/[^\d]/g, "")
					: "tel:5733748113",
				active: true,
			},
			{
				id: "share",
				text: "Share",
				icon: faShareAlt,
				iconFixedWidth: true,
				css: {
					classes: "btn theme-bg w-full",
				},
				clicked: () => (this.shareButtonsActive = !this.shareButtonsActive),
				active: true,
			},
			{
				id: "top",
				text: "Top",
				icon: faArrowCircleUp,
				iconFixedWidth: true,
				css: {
					classes: "btn top-button w-full",
				},
				clicked: () => {
					this.document.body.scrollIntoView();
				},
				active: true,
			},
		];

		this.shareButtons = [
			{
				id: "facebook",
				icon: faFacebook,
				iconFixedWidth: true,
				service: "facebook",
			},
			{
				id: "pinterest",
				icon: faPinterest,
				iconFixedWidth: true,
				service: "pinterest",
			},
			{
				id: "twitter",
				icon: faTwitter,
				iconFixedWidth: true,
				service: "twitter",
			},
			{
				id: "copylink",
				icon: faCopy,
				iconFixedWidth: true,
				service: "copylink",
			},
		];

		this.shareButtonsActive = false;

		const activeButtons = this.buttons.filter(({ active }: any) => active);
		this.buttonStyle.width = 100 / activeButtons.length + "%";

		this.initialButtons = cloneDeep(this.buttons);
		const callButton = this.buttons.find(({ id }: any) => id === "call");
		if (callButton && this.dealerService.phoneNumber) {
			callButton.link =
				"tel:" + this.dealerService.phoneNumber ? this.dealerService.phoneNumber.replace(/[^\d]/g, "") : "";
			console.log(callButton);
		}

		app.register("QuickBarUpdate", (updates: any) => {
			let modified = false;
			if (updates.hasOwnProperty("hide")) {
				for (const k of updates.hide) {
					const b = this.buttons.find(({ id }: any) => id === k);
					if (b) {
						b.active = false;
						modified = true;
					}
				}
			}

			if (updates.hasOwnProperty("prepend")) {
				for (const p of updates.prepend) {
					if (!this.buttons.find(({ id }: any) => id === p.id)) {
						if (p.hasOwnProperty("data")) {
							for (const [key, value] of iterObj<any>(p.data)) {
								(this as any)[key] = value;
							}
						}
						this.buttons.unshift(Object.assign({ cssClasses: "btn theme-bg" }, p));
					} else {
						const b = this.buttons.find(({ id }: any) => id === p.id);
						b.active = true;
					}
				}

				modified = true;
			}

			if (updates.hasOwnProperty("append")) {
				for (const a of updates.append) {
					if (!this.buttons.find(({ id }: any) => id === a.id)) {
						this.buttons.push(Object.assign({ cssClasses: "btn theme-bg" }, a));
					} else {
						const b = this.buttons.find(({ id }: any) => id === a.id);
						b.active = true;
					}
				}

				modified = true;
			}

			if (updates.hasOwnProperty("update")) {
				for (const u of updates.update) {
					const b = this.buttons.find(({ id }: any) => id === u.id);

					if (b) {
						for (const [k, v] of iterObj(u)) {
							b[k] = v;
						}
					}
				}

				modified = true;
			}

			if (modified) {
				this.buttons = cloneDeep(this.buttons);
			}
			const activeButtons = this.buttons.filter(({ active }: any) => active);
			this.buttonStyle.width = 100 / activeButtons.length + "%";
			this.cd.markForCheck();
		});

		router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.buttons = cloneDeep(this.initialButtons);
			const activeButtons = this.buttons.filter(({ active }: any) => active);
			this.buttonStyle.width = 100 / activeButtons.length + "%";
			this.cd.markForCheck();
		});
	}

	share(btn: any) {
		if (btn.service === "copylink") {
			const input = document.createElement("input");
			input.id = "copy_url_input";
			input.type = "text";
			input.style.display = "none;";
			input.value = this.document.URL;
			document.body.appendChild(input);

			input.select();
			input.setSelectionRange(0, 99999); /*For mobile devices*/

			document.execCommand("copy");
			this.toastr.success("URL Copied To Clipboard:\n" + this.document.URL);

			return;
		}
		if (btn.service === "facebook") {
			FB.ui(
				{
					method: "share",
					display: "popup",
					href: this.document.URL + "?utm_source=share&utm_medium=facebook&utm_category=share",
				},
				(response: any) => {},
			);
		}
		if (btn.service === "pinterest") {
			PinUtils.pinAny();
		}
		if (btn.service === "twitter") {
			var url =
				"https://twitter.com/intent/tweet?url=" +
				encodeURIComponent(this.document.URL + "?utm_source=share&utm_medium=twitter&utm_category=share");
			const TwitterWindow = window.open(
				url,
				"TwitterWindow",
				"height=450, width=550, top=" +
					(window.innerHeight / 2 - 225) +
					", left=" +
					(window.innerWidth / 2 - 275) +
					", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0",
			);
			return false;
		}
	}
}
