<!--Desktop-->
<ul *ngIf="!(viewport.bp.down.sm$ | async)" class="cm-menu-ul hidden md:flex">
	<li
		*ngFor="let item of menuData | async"
		class="relative"
		(mouseover)="showDropdown(item, true); item.subOpen = true"
		(mouseleave)="showDropdown(item, false); item.subOpen = false"
	>
		<ng-container *ngTemplateOutlet="items; context: { item: item }"></ng-container>
	</li>
</ul>
<!--Mobile-->
<div
	*ngIf="(menuShow$ | async) && (viewport.bp.down.sm$ | async)"
	class="cm-menu-mobile-overlay md:hidden"
	(click)="toggleMenuS.next(false)"
></div>
<div class="hamburger-container md:hidden flex items-center justify-center" (click)="toggleMenuS.next(true)">
	<small>Menu</small>
	<fa-icon [icon]="faBars" class="ml-1"></fa-icon>
</div>
<div *ngIf="viewport.bp.down.sm$ | async" class="cm-menu-mobile md:hidden" [ngClass]="{ visible: menuShow$ | async }">
	<div class="cm-menu-mobile-header theme-bg text-light">
		<span class="user mr-1">Hello, {{ userService.name$ | async }}</span>
		<fa-icon [icon]="faTimes" (click)="toggleMenuS.next(false)"></fa-icon>
		<div class="user-buttons" *ngIf="userService.loggedIn$ | async">
			<hr />
			<a
				*ngIf="userService.isCustomer$ | async"
				routerLink="/your-favorite-rvs"
				itemprop="url"
				class="btn btn-special"
				(click)="toggleMenuS.next(false)"
			>
				<fa-icon [icon]="faHeart"></fa-icon>
				<span class="ml-1" itemprop="name">Favs</span>
			</a>
			<button class="btn btn-special" (click)="logout()">
				<fa-icon [icon]="faSignOut"></fa-icon><span class="ml-1" itemprop="name">Logout</span>
			</button>
		</div>
	</div>
	<ul class="cm-menu-mobile-ul">
		<li>
			<a routerLink="/" (click)="toggleMenuS.next(false)" itemprop="url">
				<span itemprop="name">Home</span>
			</a>
		</li>
		<li *ngFor="let item of menuData | async">
			<ng-container *ngTemplateOutlet="items; context: { item: item }"></ng-container>
		</li>
	</ul>
</div>

<ng-template #items let-item="item">
	<ng-container *ngIf="item.link && !item.stmtid; else noLink">
		<a
			*ngIf="item.external"
			class="dropdown-toggle"
			[href]="!item.stmtid ? item.link : null"
			itemprop="url"
			target="_blank"
			(click)="itemClick($event, item)"
		>
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</a>
		<a
			*ngIf="!item.external"
			class="dropdown-toggle"
			[routerLink]="!item.stmtid ? item.link : null"
			itemprop="url"
			target="{{ item.target }}"
			(click)="itemClick($event, item)"
		>
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</a>
	</ng-container>
	<ng-template #noLink>
		<span class="dropdown-toggle" (click)="itemClick($event, item)">
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</span>
	</ng-template>

	<ng-template #itemContent>
		<fa-icon *ngIf="item.icon" class="menu-icon" [icon]="item.icon"></fa-icon>
		<cm-image2 *ngIf="item.image" [image]="item.image"></cm-image2>
		<span itemprop="name" [innerHTML]="item.text"></span>
		<fa-icon
			*ngIf="
				(item.items$ && (item.items$ | async)?.length > 0) ||
				(item.items && item.items.length > 0) ||
				(item.megaMenu && !(viewport.bp.down.md$ | async))
			"
			[icon]="faCaretDown"
		></fa-icon>
	</ng-template>

	<ul
		*ngIf="!item.megaMenu && item.items$ && (item.items$ | async)?.length > 0"
		class="dropdown-menu regular {{ item.cssClasses || '' }}"
		[ngClass]="{ visible: item.visible, stmtmenu: item.stmtid }"
	>
		<li
			*ngFor="let subitem of item.items$ | async"
			(mouseover)="showDropdown(subitem, true); subitem.subOpen = true"
			(mouseleave)="showDropdown(subitem, false); subitem.subOpen = false"
			[ngClass]="{ submenu: subitem.subOpen }"
		>
			<ng-container *ngTemplateOutlet="items; context: { item: subitem }"></ng-container>
		</li>
	</ul>
	<ul
		*ngIf="!item.megaMenu && item.items && item.items.length > 0"
		class="dropdown-menu regular {{ item.cssClasses || '' }}"
		[ngClass]="{ visible: item.visible, stmtmenu: item.stmtid }"
	>
		<li
			*ngFor="let subitem of item.items"
			(mouseover)="showDropdown(subitem, true); subitem.subOpen = true"
			(mouseleave)="showDropdown(subitem, false); subitem.subOpen = false"
			[ngClass]="{ submenu: subitem.subOpen }"
		>
			<ng-container *ngTemplateOutlet="items; context: { item: subitem }"></ng-container>
		</li>
	</ul>
	<ul
		*ngIf="!(viewport.bp.down.md$ | async) && item.megaMenu && item.showDropdown"
		class="dropdown-menu regular filter {{ item.cssClasses || '' }}"
	>
		<li class="p-2">
			<cm-search-filter
				*ngIf="item.new"
				[filters]="newFilter.filters$ | async"
				[searchUrl]="item.link"
				[filterSettings]="filterSettings"
			></cm-search-filter>
			<cm-search-filter
				*ngIf="item.used"
				[filters]="usedFilter.filters$ | async"
				[searchUrl]="item.link"
				[filterSettings]="filterSettings"
			></cm-search-filter>
			<cm-search-filter
				*ngIf="item.clearance"
				[filters]="clearanceFilter.filters$ | async"
				[searchUrl]="item.link"
				[filterSettings]="filterSettings"
			></cm-search-filter>
		</li>
	</ul>
</ng-template>
