import { Component, EventEmitter, Input, Output, OnInit, SimpleChanges, OnChanges } from "@angular/core";
import { ISearchFilterOption } from "@model/search-filter-option";
import { Options, LabelType } from "@m0t0r/ngx-slider";

@Component({
	selector: "cm-range-slider-selector",
	templateUrl: "./range-slider-selector.component.html",
	styleUrls: ["./range-slider-selector.component.scss"],
	host: {
		"[class.filter-row]": "true",
	},
})
export class RangeSliderSelectorComponent implements OnChanges {
	// @ts-ignore
	@Input() range: ISearchFilterOption;
	@Output() select = new EventEmitter();
	options: Options = {
		floor: 0,
		ceil: 1000,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
				case LabelType.Low:
					return "<b>Min:</b> $" + value;
				case LabelType.High:
					return "<b>Max:</b> $" + value;
				default:
					return "$" + value;
			}
		},
	};
	min = 0;
	max = 1000;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.range && changes.range.currentValue) {
			this.setValues();
		}
	}

	setValues() {
		if (this.range.queryParams[`min_${this.range.name}`]) {
			this.min = this.range.queryParams[`min_${this.range.name}`];
		}
		if (this.range.queryParams[`max_${this.range.name}`]) {
			this.max = this.range.queryParams[`max_${this.range.name}`];
		}
	}

	applyRange() {
		this.sendChange();
	}

	sendChange() {
		this.select.emit({
			type: this.range.type,
			name: this.range.name,
			min: this.min,
			max: this.max,
		});
	}
}
