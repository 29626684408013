import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CacheService } from "./cache.service";
import { iterObj } from "@common/iter";
import { ConfigService } from "./config.service";
import { IPageData } from "@model/page-data";

export class Service {
	url: string = "";
	cacheService: any = null;
	localCache: any = null;
	appToken: any = null;
	cacheKeyPrefix: any = "Cache:";

	constructor(public op: string, public cacheTag: any, public cacheStorageType: any, public service: any) {
		this.url = op ? "/api/" + this.op : "/api";
		this.cacheService = this.service.CacheService.init(cacheStorageType);
		this.localCache = this.service.CacheService.init("localStorage");
	}

	executeRequest(
		httpVerb: any,
		data: any,
		id: any,
		cacheKey: any,
		cachedQueries: any,
		multipart: boolean = false,
		noRedirect: boolean,
		asPromise: boolean,
	) {
		let requestUrl: any = id ? this.url + "/" + id : this.url;
		let requestData: any = data || {};
		const singleCall: any = httpVerb === "GET" || httpVerb === "DELETE";

		if (singleCall) {
			requestData =
				singleCall && (Array.isArray(data) || data instanceof Array) ? data.join("&") + "&" || "" : "";
			requestUrl += "?" + requestData + `appId=1&siteConfigId=${this.service.pageData.appInfo.data.siteConfigId}`;
			requestUrl += cacheKey ? "&cacheKey=" + this.cacheKeyPrefix + cacheKey : "";
		} else {
			requestData.appId = 1;
			requestData.siteConfigId = this.service.pageData.appInfo.data.siteConfigId;
			requestData.cacheTag = this.cacheTag;

			if (cacheKey) {
				requestData.cacheKey = this.cacheKeyPrefix + cacheKey;
			}
		}

		let request: any = null;

		if (!multipart) {
			switch (httpVerb) {
				case "GET":
					request = this.service.http.get(requestUrl, {});
					break;
				case "POST":
					request = this.service.http.post(requestUrl, requestData, {});
					break;
				case "PUT":
					request = this.service.http.put(requestUrl, requestData, {});
					break;
				case "DELETE":
					request = this.service.http.delete(requestUrl, {});
					break;
			}
		} else {
			const formData = new FormData();
			for (const [key, val] of iterObj<any>(requestData)) {
				if (Array.isArray(val)) {
					for (let i = 0; i < val.length; i++) {
						if (val[i].constructor.name === "FileList") {
							// tslint:disable-next-line: prefer-for-of
							for (let j = 0; j < val[i].length; j++) {
								const file = val[i][j];
								formData.append(`${key}[${i}]`, file);
							}
						} else {
							formData.append(`${key}[${i}]`, val[i]);
						}
					}
				} else if (typeof val === "object") {
					formData.append(key, JSON.stringify(val));
				} else {
					formData.append(key, val);
				}
			}
			requestUrl = requestUrl + "?ngsw-bypass=true";
			request = this.service.http.post(requestUrl, formData, { headers: { "ngsw-bypass": true } });
		}

		if (asPromise) {
			request = request.toPromise();
			return request.then(
				(response: any) => {
					return this.handleSuccess(response, {
						httpVerb: httpVerb,
						data: data,
						id: id,
						cacheKey: cacheKey,
						cacheTag: this.cacheTag,
						cachedQueries: cachedQueries,
					});
				},
				(response: any) => this.handleError(response, noRedirect),
			);
		} else {
			return request;
		}
	}

	findMessage(obj: any): any {
		if (typeof obj === "object" && null !== obj) {
			if (obj.hasOwnProperty("message")) {
				return obj.message;
			} else {
				for (const k in obj) {
					if (obj.hasOwnProperty(k)) {
						if (typeof obj[k] === "object") {
							return this.findMessage(obj[k]);
						}
					}
				}

				return null;
			}
		}
		return null;
	}

	handleError(response: any, noRedirect: boolean) {
		if (response.status && response.status === 401) {
			// User is not authenticated
			if (this.service.location.path() === "/new-admin" || noRedirect) {
				return Promise.reject(response);
			} else {
				this.service.location.go("/new-admin");
				return Promise.reject(response);
			}
		} else if (response.status && response.status === 403) {
			// User doesn't have access to requested resource
			this.service.location.go("/new-admin");
		} else if (null !== response && response.hasOwnProperty("message")) {
			// look for a nested message
			response.message = this.findMessage(response);

			return Promise.reject(response);
		} else {
			return Promise.reject("An unknown error occurred.");
		}
	}

	handleSuccess(response: any, request: any) {
		if (typeof response === "string") {
			response = {
				success: true,
				message: "",
				result: response,
			};
		}

		if (!response || response === "") {
			return;
		}

		if (!response.success) {
			if (!response.hasOwnProperty("message")) {
				response.message = this.findMessage(response) || "An unknown error occurred";
			}
		}

		try {
			if (request.httpVerb === "POST" && request.id === "m") {
				if (request.data.hasOwnProperty("queries")) {
					for (const q of request.data.queries) {
						if (q.hasOwnProperty("cache") && q.cache) {
							this.cacheService.set(this.cacheKeyPrefix + q.id, response.queries[q.id]);
						}
					}

					for (const q of request.cachedQueries) {
						if (this.cacheService.has(this.cacheKeyPrefix + q.id)) {
							response.queries[q.id] = this.cacheService.get(this.cacheKeyPrefix + q.id);
						}
					}
				}
			}

			if (request.cacheKey) {
				this.cacheService.set(this.cacheKeyPrefix + request.cacheKey, response);
			}
		} catch (e) {
			console.error("Cache Error", e);
		}

		return response;
	}

	makeRequest(
		httpVerb: any,
		data: any,
		id?: any,
		cacheKey?: any,
		multipart: boolean = false,
		noRedirect: boolean = false,
		asPromise: boolean = true,
	) {
		const cachedQueries: any = [];
		let cached: any = true;

		cacheKey = cacheKey ? this.service.pageData.appInfo.data.siteConfigId + ":" + cacheKey : null;

		if (cached && cacheKey && this.cacheService.has(this.cacheKeyPrefix + cacheKey)) {
			return Promise.resolve(this.cacheService.get(this.cacheKeyPrefix + cacheKey)).then((response: any) => {
				return response;
			});
		}

		if (cached && httpVerb === "POST" && id === "m") {
			if (data.hasOwnProperty("queries")) {
				const remove: any = [];

				for (const [index, q] of iterObj<any>(data.queries)) {
					if (q.hasOwnProperty("cache") && q.cache) {
						if (this.cacheService.has(this.cacheKeyPrefix + q.id)) {
							remove.push(index);
						}
					}
				}

				for (const i of remove) {
					cachedQueries.push(data.queries.splice(i, 1)[0]);
				}
			}
		}

		return this.executeRequest(httpVerb, data, id, cacheKey, cachedQueries, multipart, noRedirect, asPromise);
	}

	create(data: any) {
		return this.makeRequest("POST", data);
	}

	list(options?: any, page?: any, cacheKey?: any) {
		const httpVerb: any = options ? "POST" : "GET";
		const extra: any = options ? "options" : null;
		const data: any = {
			options: options,
		};

		if (page) {
			data.page = page;
		}

		return this.makeRequest(httpVerb, data, extra, cacheKey);
	}

	custom(httpVerb: any, path: any, data?: any, cacheKey?: any) {
		return this.makeRequest(httpVerb, data, path, cacheKey);
	}

	one(id: any, data?: any, cacheKey?: any) {
		return this.makeRequest("GET", data, id, cacheKey);
	}

	get$(id: string, data?: any, cacheKey?: string): Observable<any> {
		return this.makeRequest("GET", data, id, cacheKey, undefined, undefined, false);
	}

	post(id: any, data?: any, cacheKey?: any, multipart: boolean = false, noRedirect: boolean = false) {
		return this.makeRequest("POST", data, id, cacheKey, multipart, noRedirect);
	}

	post$(
		id: any,
		data?: any,
		cacheKey?: any,
		multipart: boolean = false,
		noRedirect: boolean = false,
	): Observable<any> {
		return this.makeRequest("POST", data, id, cacheKey, multipart, noRedirect, false);
	}

	remove(id: any, options: any) {
		const httpVerb: any = options ? "POST" : "DELETE";
		const extra: any = options ? "delete/" + id : id;
		const data: any = options ? { options: options } : null;

		return this.makeRequest(httpVerb, data, extra);
	}

	delete(id: any, options: any) {
		const httpVerb: any = options ? "POST" : "DELETE";
		const extra: any = id;
		const data: any = options ? { options: options } : null;

		return this.makeRequest(httpVerb, data, extra);
	}

	update(id: any, data: any) {
		return this.makeRequest("PUT", data, id);
	}
}

@Injectable({ providedIn: "root" })
export class RestService {
	constructor(
		public config: ConfigService,
		public CacheService: CacheService,
		public http: HttpClient,
		public location: Location,
		@Inject("PAGE_DATA") public pageData: IPageData,
	) {}

	init(op: string, cacheTag?: string, cacheStorageType?: string) {
		return new Service(op, cacheTag || "General", cacheStorageType || "sessionStorage", this);
	}
}
