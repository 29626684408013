import { Inject, Injectable } from "@angular/core";
import { IPageData } from "@model/page-data";

@Injectable({
	providedIn: "root",
})
export class ListingService {
	noImage = {
		img_dir: "static/" + this.pageData.appInfo.data.siteConfigId + "/",
		img_file: "no-photos.jpg",
		img_alt: "Photos Coming Soon",
		width: 340,
		height: 225,
	};
	floorPlan = {
		img_dir: "static/" + this.pageData.appInfo.data.siteConfigId + "/",
		img_file: "floor-plan-coming-soon.jpg",
		img_alt: "Floor Plan Coming Soon",
		width: 340,
		height: 225,
	};
	customBuild = {
		img_dir: "static/" + this.pageData.appInfo.data.siteConfigId + "/",
		img_file: "still-at-factory.jpg",
		img_alt: "Still At The Factory",
		width: 340,
		height: 225,
	};
	constructor(@Inject("PAGE_DATA") private pageData: IPageData) {}

	makeCardObject(listing: any) {
		const listingTitle = `${listing.model_num} ${listing.brand}`;
		const shortVin = listing.vin ? listing.vin.substr(-6) : "";
		const promotionImages: any = [];
		const url: any = null !== listing.promotionImgs ? listing.promotionImgs.split(",") : [];
		const images: any[] = [
			listing.img_file
				? {
						img_dir: listing.img_dir,
						img_file: listing.img_file,
						img_width: listing.img_width,
						img_height: listing.img_height,
						img_alt: listing.img_alt,
				  }
				: null,
			listing.img3_file || listing.img_file3
				? {
						img_dir: listing.img3_dir || listing.img_dir3,
						img_file: listing.img3_file || listing.img_file3,
						img_width: listing.img_width3,
						img_height: listing.img_height3,
						img_alt: listing.img_alt3,
				  }
				: null,
		];
		for (const item of url) {
			if (item != listing.promotion_img_dir + listing.promotion_img_file) promotionImages.push(item);
		}

		if (!images[0] && !images[1]) {
			if (images[0] === null) {
				images[0] = {};
			}

			if (images[1] === null) {
				images[1] = {};
			}

			images[0] = this.noImage;
		}

		if (!images[1]) {
			images[1] = this.floorPlan;
		}

		if (!images[0] && images[1]) {
			if (listing.on_order === 1) {
				images[0] = this.customBuild;
			} else {
				images[0] = this.noImage;
			}
		}

		return {
			vehicle_type: listing.vehicle_type,
			model_year: listing.hide_year ? null : listing.model_year,
			listingid: listing.listingid,
			condition: listing.condition,
			sectionHeader:
				listing.brand +
				(listing.parent_vehicle_type ? " " + listing.parent_vehicle_type : "") +
				" " +
				listing.vehicle_type,
			shortVin: shortVin,
			location:
				listing.location ||
				(listing.on_order === 1 && listing.sold !== 1 ? "ON ORDER" : listing.sold === 1 ? "SOLD" : ""),
			unitName: listingTitle,
			heading: listingTitle + (listing.hide_year === 0 && shortVin ? " - " + shortVin : ""),
			msrp: listing.msrp,
			estMonthly:
				0 < listing.est_monthly_payment && null !== listing.est_monthly_payment
					? listing.est_monthly_payment
					: false,
			price: listing.price,
			promotionImages,
			images: images,
			imageCount: listing.imgCount,
			currentImage: images[0] ? images[0] : images[1],
			link: listing.content_page_url,
			showPrice: listing.show_price === 1,
			sold: listing.sold === 1,
			videos: listing.videos === 1,
			virtualTour: listing.virtualTour === 1,
			onOrder: listing.on_order === 1,
			pending: listing.pending_sale === 1,
			map: listing.map,
			callNow: listing.phone_number !== null,
			phoneNumber: listing.phone_number,
			tagline: listing.tagline || (listing.condition === "New" ? "Pre-Order Today" : "Call Today"),
			promotionImage: listing.promotion_img_dir + listing.promotion_img_file,
		};
	}
}
