import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { merge, Observable, of, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";

@Component({
	selector: "cm-search",
	template: `
		<div class="flex items-center">
			<input
				class="form-control cm-text-field theme-bg-as-fg theme-border w-11/12 mr-1"
				itemprop="query-input"
				type="text"
				name="search"
				[formControl]="searchControl"
				[placeholder]="placeholder"
				[matAutocomplete]="auto"
			/>
			<mat-spinner *ngIf="searching"></mat-spinner>
			<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="absolute right-0 w-96">
				<mat-option
					*ngFor="let item of searchItemsTemp | async"
					[value]="item.label"
					(onSelectionChange)="onSelect(item)"
				>
					<a
						routerLink="{{ item.href }}"
						*ngIf="item.href"
						tabindex="-1"
						[title]="item.label"
						class="text-reset"
					>
						{{ item.label }}
					</a>
					<span *ngIf="!item.href" tabindex="-1" title="{{ item.label }}">
						{{ item.label }}
					</span>
				</mat-option>
			</mat-autocomplete>
		</div>
	`,
})
export class SearchComponent implements OnInit {
	searchControl = new FormControl();
	searchItemsTemp!: Observable<any>;
	@Input() placeholder: string = "Search...";
	@Input() buttonClass: string = "outline-secondary";
	@Input() searchString: string = "";
	@Input() focusFirst: boolean = false;
	@Input() buttonText?: string;
	@Input() buttonIcon?: any;
	@Input() buttonClick: SearchButtonClick = () => {
		/** do nothing */
	};
	@Input() searchFn: SearchFunction = () => of([]);
	// TODO: Allow search component to accept template

	@Output() search: EventEmitter<string> = new EventEmitter();
	@Output() result: EventEmitter<any> = new EventEmitter();

	clearS = new Subject();

	onSearch = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => (this.searching = true)),
			switchMap((text) => merge(of(text), this.clearS.pipe(map(() => "")))),
			switchMap((text) => (text ? this.searchFn(text) : of([]))),
			tap(() => (this.searching = false)),
		);
	};

	onSelect(item: any) {
		this.result.emit(item);
	}

	// ngOnChanges(changes: SimpleChanges): void {
	// 	if (changes.searchString && changes.searchString.currentValue) {
	// 		this.searchString = changes.searchString.currentValue;
	// 	}
	// }

	ngOnInit() {
		this.searchItemsTemp = this.searchControl.valueChanges.pipe(
			debounceTime(600),
			distinctUntilChanged(),
			tap(() => (this.searching = true)),
			switchMap((text) => merge(of(text), this.clearS.pipe(map(() => "")))),
			switchMap((text) => (text ? this.searchFn(text) : of([]))),
			tap(() => (this.searching = false)),
		);
	}

	formatter = (x: { label: string }) => x.label;

	searching: boolean = false;

	private scrollIntoViewIfNeededPolyfill(elem: HTMLElement, centerIfNeeded = true) {
		const parent = elem.parentElement;
		const parentComputedStyle = window.getComputedStyle(parent!, null);
		const parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue("border-top-width"));
		const parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue("border-left-width"));
		const overTop = elem.offsetTop - parent!.offsetTop < parent!.scrollTop;
		const overBottom =
			elem.offsetTop - parent!.offsetTop + elem.clientHeight - parentBorderTopWidth >
			parent!.scrollTop + parent!.clientHeight;
		const overLeft = elem.offsetLeft - parent!.offsetLeft < parent!.scrollLeft;
		const overRight =
			elem.offsetLeft - parent!.offsetLeft + elem.clientWidth - parentBorderLeftWidth >
			parent!.scrollLeft + parent!.clientWidth;
		const alignWithTop = overTop && !overBottom;

		if ((overTop || overBottom) && centerIfNeeded) {
			parent!.scrollTop =
				elem.offsetTop -
				parent!.offsetTop -
				parent!.clientHeight / 2 -
				parentBorderTopWidth +
				elem.clientHeight / 2;
		}

		if ((overLeft || overRight) && centerIfNeeded) {
			parent!.scrollLeft =
				elem.offsetLeft -
				parent!.offsetLeft -
				parent!.clientWidth / 2 -
				parentBorderLeftWidth +
				elem.clientWidth / 2;
		}

		if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
			elem.scrollIntoView(alignWithTop);
		}
	}
}

export type SearchButtonClick = (searchString: string | null) => void;
export type SearchFunction = (text: string) => Observable<any[]>;
