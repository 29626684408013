import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { ISearchFilterOption } from "@model/search-filter-option";

@Component({
	selector: "cm-range-selector",
	templateUrl: "./range-selector.component.html",
	styleUrls: ["./range-selector.component.scss"],
	host: {
		"[class.filter-row]": "true",
	},
})
export class RangeSelectorComponent implements OnChanges {
	// @ts-ignore
	@Input() range: ISearchFilterOption;
	@Output() select = new EventEmitter();

	min = "";
	max = "";

	tracker = (index: number, result: any) => result.value;

	constructor(private viewport: ViewportService) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.range && changes.range.currentValue) {
			this.setValues();
		}
	}

	setValues() {
		if (this.range.queryParams[`min_${this.range.name}`]) {
			this.min = this.range.queryParams[`min_${this.range.name}`];
		}
		if (this.range.queryParams[`max_${this.range.name}`]) {
			this.max = this.range.queryParams[`max_${this.range.name}`];
		}
	}

	sendChange() {
		this.select.emit({
			type: this.range.type,
			name: this.range.name,
			min: this.min.length ? this.min : null,
			max: this.max.length ? this.max : null,
		});
	}

	setMin(val: string) {
		if (val !== this.min) {
			this.min = val;
			this.sendChange();
		}
	}

	setMax(val: string) {
		if (val !== this.max) {
			this.max = val;
			this.sendChange();
		}
	}
}
