import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@core/app/shared/shared.module";
import { UtilModule } from "shared";
import { InventoryModule } from "../inventory/inventory.module";
import { MenuComponent } from "../menu/menu.component";
import { HeaderFnsComponent } from "./components/header-fns/header-fns.component";

@NgModule({
	declarations: [HeaderFnsComponent, MenuComponent],
	imports: [CommonModule, SharedModule, UtilModule, InventoryModule],
	exports: [HeaderFnsComponent],
})
export class HeaderModule {}
