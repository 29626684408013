<cm-json-ld [json]="ld$ | async"></cm-json-ld>
<cm-header-fns></cm-header-fns>
<cm-user-widget></cm-user-widget>
<cm-edit-widget></cm-edit-widget>
<div id="site-wrapper" class="site-wrapper">
	<router-outlet></router-outlet>
	<cm-promotion-banner [bannerCat]="'PromoBottom'"></cm-promotion-banner>
</div>
<cm-promotion-popup></cm-promotion-popup>
<cm-footer-fns></cm-footer-fns>
<cm-quick-bar></cm-quick-bar>
<div toastContainer></div>
