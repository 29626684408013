<div class="flex justify-between">
	<h4 class="modal-title" id="modal-basic-title">Upload Files</h4>
	<button class="close" aria-label="Close" (click)="dismiss.emit()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<ng-container *ngIf="uploadedFiles.length === 0 && !uploading && categories">
		<div class="mt-3">
			<label for="url">Enter a URL to import an image into the library</label>
			<div class="input-group mb-3">
				<input type="text" id="url" class="form-control" [(ngModel)]="url" />
				<select [(ngModel)]="urlCategory" required>
					<option value=""></option>
					<option *ngFor="let cat of categories" [value]="cat.id">
						{{ cat.name }}
					</option>
				</select>
				<div class="input-group-append">
					<button mat-raised-button color="primary" [disabled]="!url || !urlCategory" (click)="onImport()">
						Import
					</button>
				</div>
			</div>
		</div>

		<div class="text-center text-muted my-3 or"><strong>OR</strong></div>

		Click on category or drag file to category to upload.

		<div class="grid grid-flow-row grid-cols-4 gap-4 dropzones">
			<div *ngFor="let cat of categories">
				<cm-dropzone
					class="h-full dropzone"
					(filesDropped)="valueChange(cat.id, $event)"
					(click)="onCatClick(cat.id)"
				>
					{{ cat.name }}
				</cm-dropzone>
			</div>
		</div>
	</ng-container>

	<ngb-progressbar *ngIf="uploading" type="info" [value]="100" [striped]="true" [animated]="true">
		Loading...
	</ngb-progressbar>

	<div *ngIf="uploadedFiles.length > 0" class="grid grid-flow-row grid-cols-4 gap-4 uploaded-pics mt-3">
		<div *ngFor="let file of uploadedFiles">
			<div
				[ngClass]="{ 'border border-primary selected': file.selected, 'p-2': mode === 'file' }"
				(click)="onFileClick(file)"
			>
				<ng-container *ngIf="mode === 'file'">
					<fa-icon [icon]="faFile"></fa-icon> {{ file.obj.filename }}
				</ng-container>
				<cm-image2 *ngIf="mode === 'image'" [image]="file.obj" class="block"></cm-image2>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<button mat-raised-button [disabled]="uploadedFiles.length === 0" color="primary" (click)="onSelect()">
		Select
	</button>
</div>
