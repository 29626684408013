import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { SMap } from "@common/map2";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { upperFirst } from "@common/util";
import { IPageData } from "@model/page-data";
import { IGetLocations } from "@model/stmt/GetLocations";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Component({
	selector: "cm-footer-fns",
	template: `
		<div class="footer-fns" itemscope itemtype="http://schema.org/WPFooter">
			<meta itemprop="copyrightYear" [content]="copyrightYear" />
			<meta itemprop="copyrightHolder" [content]="dealer" />
			<div class="container-fluid">
				<div class="footer-content row flex-col items-center py-12">
					<div class="col w-full lg:w-5/6">
						<div class="row">
							<div class="company-info col w-full lg:w-5/12">
								<div class="row">
									<div class="col w-full sm:w-1/2 my-2" *ngFor="let loc of locations$ | async">
										<div class="text-xl">{{ loc.location }}</div>
										<div class="address py-2">
											{{ loc.address1 }}<br />
											<ng-container *ngIf="loc.address2">{{ loc.address2 }}<br /></ng-container>
											{{ loc.city }}, {{ loc.abbrev }} {{ loc.postal_code }}
										</div>
										<div class="get-directions">
											<a [href]="getDirectionsLink(loc)" target="_blank">
												<strong>Get Directions ></strong>
											</a>
										</div>
										<div class="phone py-2">
											<a href="tel:{{ loc.org_phone_number }}" rel="nofollow">{{
												loc.org_phone_number
											}}</a>
										</div>
										<ng-container *ngFor="let hourType of loc.hours$ | async">
											<div class="font-bold text-red-500">{{ hourType[0] }} Hours</div>
											<div class="hours-wrapper">
												<div
													*ngFor="let hourLine of hourType[1]"
													class="hour flex items-center"
												>
													<span class="day-of-week w-1/2 pr-1 pl-0">{{
														hourLine[1].join(", ")
													}}</span>
													<span class="day-hours w-1/2 pr-0 pl-1">{{ hourLine[0] }}</span>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
							<div class="col lg:w-1/6 divider hidden lg:block">
								<div class="divider-line"></div>
							</div>
							<div class="col w-full lg:w-5/12 mt-4 lg:mt-0">
								<div class="row">
									<div class="col w-full lg:w-7/12">
										<div class="row">
											<div
												class="col w-1/2"
												*ngFor="let column of footerLinks$ | async; let index = index"
											>
												<ul class="footer-links mb-6">
													<li *ngFor="let link of column; let index = index">
														<a
															*ngIf="link.link.indexOf('http') === -1"
															[routerLink]="[link.link]"
															[attr.target]="link.target"
															>{{ link.text }}</a
														>
														<a
															*ngIf="link.link.indexOf('http') !== -1"
															href="{{ link.link }}"
															[attr.target]="link.target"
															>{{ link.text }}</a
														>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="col w-full lg:w-5/12">
										<div class="copyright">
											<span>&copy; {{ copyrightYear }} {{ dealer }}</span
											><br />
											<a routerLink="/privacy-policy">Privacy Policy</a><br />
											<strong
												><a href="https://designforcemarketing.com/"
													>Powered By Design Force Marketing</a
												></strong
											>
										</div>
									</div>
									<div class="col w-full text-white py-1">
										AOK RVs has made every effort to ensure the accuracy of each listing. We are not
										responsible for incorrect photographs, errors in the camper’s description or
										pricing errors. Sometimes, the build of a camper changes at the manufacturer due
										to a variety of factors, and our dealership is not notified. Please contact AOK
										RVs to verify any price or specific features that are important to you.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			.divider-line {
				width: 5px;
				height: 100%;
				border-left: 1px solid #fff;
				margin: 0 auto;
			}
			.footer-fns {
				position: relative;
				top: 80px;
				font-size: 12px;
				background: #000000;
				color: white;
				padding-bottom: 25px;
			}
			.footer-fns a {
				color: white;
			}
			.footer-links {
				padding-left: 0px;
			}
			.footer-links li {
				list-style-type: none;
			}
			@media print {
				.footer-fns {
					background: 0 0 !important;
					color: #000 !important;
					box-shadow: none !important;
					text-shadow: none !important;
				}
				a {
					color: #000 !important;
				}
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterFnsComponent {
	copyrightYear = new Date().getFullYear();
	dealer: string;
	locations$: any;
	footerLinks$: Observable<any> = of([]);
	constructor(@Inject("PAGE_DATA") private pageData: IPageData, http: HttpClient, transfer: TransferRxService) {
		this.dealer = this.pageData.appInfo.data.siteName;
		this.locations$ = transfer
			.transfer$("GetLocations", () => http.post("/api/statement/GetLocations", {}))
			.pipe(
				map((response: any) =>
					response.results.map((row: any) => ({
						...row,
						hours$: transfer
							.transfer$("GetLocationDeptHours:" + row.locationid, () =>
								http.post("/api/statement/GetLocationDeptHours", {
									vars: { locationid: row.locationid },
								}),
							)
							.pipe(
								map((response: any) => {
									const hours: SMap<string, SMap<string, string[]>> = new SMap();
									for (const row of response.results) {
										let from = row.time_from;
										if (from && from.startsWith("0")) {
											from = from.substring(1);
										}

										let to = row.time_to;
										if (to && to.startsWith("0")) {
											to = to.substring(1);
										}

										const hourRange = from && to ? `${from} - ${to}` : "Closed";

										hours
											.getOrInsertWith(row.org_dept, () => new SMap())
											.getOrInsert(hourRange, [])
											.push(upperFirst(row.day_of_week.toLowerCase()).substr(0, 3));
									}
									return hours;
								}),
							),
					})),
				),
			);
		this.footerLinks$ = transfer
			.transfer$("GetAppMenuWithImages", () =>
				http.post("/api/statement/GetAppMenuWithImages", {
					vars: {
						app_menu: "Footer Menu",
						auth_role: "Public",
					},
				}),
			)
			.pipe(
				map((response: any) => {
					const links: any[] = [];
					let index = 0;
					links[index] = [];
					const limit = Math.ceil(response.results.length / 2);
					for (const item of response.results) {
						links[index].push({
							text: item.app_menu_item,
							link: item.custom_url || item.content_page_url,
							target: item.url_target,
						});
						if (links[index].length >= limit) {
							index++;
							links[index] = [];
						}
					}
					return links;
				}),
			);
	}

	getDirectionsLink(loc: IGetLocations) {
		let param: string = loc.address1!;
		if (loc.address2) {
			param += ` ${loc.address2}`;
		}
		param += ` ${loc.city}, ${loc.abbrev} ${loc.postal_code}`;
		return `https://www.google.com/maps/dir//${param.replace(/\s/g, "+")}`;
	}
}
