import { CommonModule, Location } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { ModalFileUploadComponent } from "@core/app/admin/components/modal-file-upload/modal-file-upload.component";
import { CampaignPickerComponent } from "@core/app/adwords/components/campaign-picker/campaign-picker.component";
import { AutocompleteComponent } from "@core/app/autocomplete/autocomplete.component";
import { ButtonListComponent } from "@core/app/button-list/button-list.component";
import { ButtonModule } from "@core/app/button/button.module";
import { ButtonComponent } from "@core/app/button/components/button/button.component";
import { CardGridComponent } from "@core/app/card-grid/card-grid.component";
import { CardComponent } from "@core/app/card/card.component";
import { CrumbsComponent } from "@core/app/crumbs/crumbs.component";
import { ClickCopyDirective } from "@core/app/directive/click-copy.directive";
import { FaqModule } from "@core/app/faq/faq.module";
import { ImageGridComponent } from "@core/app/image-grid/image-grid.component";
import { ImageModule } from "@core/app/image/image.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { LazyModule } from "@core/app/lazy/lazy.module";
import { ListingGridComponent } from "@core/app/listing-grid/listing-grid.component";
import { Map2Module } from "@core/app/map2/map2.module";
import { Pagination2Module } from "@core/app/pagination2/pagination2.module";
import { PaymentCalculatorComponent } from "@core/app/payment-calculator/payment-calculator.component";
import { GroupByPipe } from "@core/app/pipes/group-by.pipe";
import {
	ImageUploadModal,
	PromotionBannerComponent,
} from "@core/app/promotion/components/promotion-banner/promotion-banner.component";
import { SearchModule } from "@core/app/search/search.module";
import { SocialLinks2Component } from "@core/app/shared/components/social-links2/social-links2.component";
import { TableFiltersComponent } from "@core/app/shared/components/table-filters/table-filters.component";
import { TableSelectsComponent } from "@core/app/shared/components/table-selects/table-selects.component";
import { TableComponent } from "@core/app/shared/components/table/table.component";
import { StarListingComponent } from "@core/app/star-listing/star-listing.component";
import { VideoComponent } from "@core/app/video/video.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { UtilModule as SharedUtilModule } from "shared";
import { FormModule } from "../form/form.module";
import {
	FnsListingCardComponent,
	ListingCardModalComponent,
} from "../listing/components/fns-listing-card/fns-listing-card.component";
import { FnsListingHortizontalCardComponent } from "../listing/components/fns-listing-hortizontal-card/fns-listing-hortizontal-card.component";
import { PanotourComponent } from "../panotour/panotour.component";
import { SafePipe } from "../pipes/safe.pipe";
import { SlugifyPipe } from "../pipes/slugify.pipe";
import { QuickBarComponent } from "../quick-bar/quick-bar.component";
import { UtilModule } from "../util/util.module";
import { EmpGridAllComponent } from "./components/emp-grid-all/emp-grid-all.component";
//import { DropzoneComponent } from "@core/app/dropzone/dropzone.component";

@NgModule({
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		ReactiveFormsModule,
		LazyModule,
		FaqModule,
		RouterModule,
		ImageModule,
		ButtonModule,
		Map2Module,
		SearchModule,
		LayoutModule,
		Pagination2Module,
		UtilModule,
		MatButtonModule,
		SharedUtilModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatButtonToggleModule,
		MatDialogModule,
		MatCardModule,
		MatTooltipModule,
		FormModule,
	],
	declarations: [
		AutocompleteComponent,
		ButtonComponent,
		ButtonListComponent,
		CardComponent,
		CardGridComponent,
		ClickCopyDirective,
		CrumbsComponent,
		GroupByPipe,
		ImageGridComponent,
		ListingGridComponent,
		ListingCardModalComponent,
		PaymentCalculatorComponent,
		StarListingComponent,
		VideoComponent,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		ModalFileUploadComponent,
		SocialLinks2Component,
		CampaignPickerComponent,
		PromotionBannerComponent,
		ImageUploadModal,
		FnsListingCardComponent,
		FnsListingHortizontalCardComponent,
		PanotourComponent,
		SafePipe,
		QuickBarComponent,
		EmpGridAllComponent,
		SlugifyPipe,
		//DropzoneComponent,
	],
	exports: [
		ReactiveFormsModule,
		CommonModule,
		FormsModule,
		RouterModule,
		LazyModule,
		FaqModule,
		Map2Module,
		SearchModule,
		LayoutModule,
		UtilModule,
		ImageModule,
		SlugifyPipe,
		SafePipe,
		GroupByPipe,
		ClickCopyDirective,
		AutocompleteComponent,
		ButtonComponent,
		ButtonListComponent,
		CardComponent,
		CardGridComponent,
		CrumbsComponent,
		FontAwesomeModule,
		ImageGridComponent,
		ListingGridComponent,
		ListingCardModalComponent,
		PaymentCalculatorComponent,
		StarListingComponent,
		VideoComponent,
		TableComponent,
		TableFiltersComponent,
		TableSelectsComponent,
		ModalFileUploadComponent,
		SocialLinks2Component,
		CampaignPickerComponent,
		Pagination2Module,
		PromotionBannerComponent,
		ImageUploadModal,
		FnsListingCardComponent,
		FnsListingHortizontalCardComponent,
		PanotourComponent,
		QuickBarComponent,
		EmpGridAllComponent,
		//DropzoneComponent,
	],
	providers: [Location],
})
export class SharedModule {}
