import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IPageData } from "@model/page-data";

@Injectable({ providedIn: "root" })
export class ConfigService {
	cdnRootPath: any = null;
	/**
	 * S3 Static Url for Images
	 */
	imgStaticUrl = "static/";

	/**
	 * Scripts available for loading dynamically
	 */
	scripts = [
		{
			key: "maps",
			enabled: false,
			src:
				"https://maps.googleapis.com/maps/api/js?key=AIzaSyDK6RybE29BiXD8VPd_BtKeE0LuvjaCcYA&cacheBuster=" +
				new Date().getTime(),
		},
		{
			key: "wistia",
			enabled: false,
			src: "//fast.wistia.com/assets/external/E-v1.js",
		},
	];

	/**
	 * Any params listed here will be cleared automatically from route checking
	 */
	specialParams = [""];

	/**
	 * Static URL
	 */
	staticUrl = "";

	/**
	 * Table Config
	 */
	table = {
		/**
		 * Column lengths are multiplied by this factor to get width of the column
		 */
		columnLengthMultiplier: 8,
		/**
		 * Column width can't exceed this number
		 */
		columnMaxLength: 150,
		/**
		 * Default Pagination Limit
		 */
		paginationLimit: 25,
	};

	private settings =
		typeof window !== "undefined" && window.sessionStorage.hasOwnProperty("settings")
			? JSON.parse(window.sessionStorage.getItem("settings")!)
			: {};

	constructor(private http: HttpClient, @Inject("PAGE_DATA") private pageData: IPageData) {}

	/**
	 * Retrieves the App Token
	 */
	getAppToken() {
		this.cdnRootPath = this.pageData.appInfo.data.cdnRootPath;
		this.staticUrl =
			this.pageData.appInfo.data.cdnRootPath + this.imgStaticUrl + this.pageData.appInfo.data.siteConfigId + "/";
		return this.pageData.appInfo.data;
	}

	/**
	 * Returns the App Token String
	 */
	getAppTokenString() {
		return `appId=1&siteConfigId=${this.pageData.appInfo.data.siteConfigId}`;
	}

	/**
	 * Fetches a specified admin setting
	 */
	getSetting(settingName: any) {
		if (this.settings.hasOwnProperty(settingName)) {
			return Promise.resolve(this.settings[settingName]);
		} else {
			return this._getSetting(settingName, this.getAppTokenString()).then((setting) => {
				if (setting) {
					this.settings[settingName] = setting;
					if (typeof window !== "undefined") {
						try {
							window.sessionStorage.setItem("settings", JSON.stringify(this.settings));
						} catch (e) {
							console.error("Storage quota exceeded!", e);
						}
					}
				}

				return setting;
			});
		}
	}

	/**
	 * Retrieves an Admin Setting from the API
	 */
	private async _getSetting(setting: any, appTokenString: any) {
		const response = (await this.http
			.get("/api/admin/setting/find/" + setting + "?" + appTokenString)
			.toPromise()) as any;

		if (response.success) {
			return response.admin_setting;
		} else {
			return null;
		}
	}
}
