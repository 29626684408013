import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { IPageData } from "@model/page-data";
import { map, shareReplay } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class MenuHelperService {
	constructor(
		@Inject("PAGE_DATA") private pageData: IPageData,
		private http: HttpClient,
		private transfer: TransferRxService,
	) {}

	getSubMenu(menus: any, depth: any) {
		let menu: any = null;
		for (let i = 1; i <= depth; i += 1) {
			if (menu) {
				menu = menu.hasOwnProperty("items") ? menu.items[menu.items.length - 1] : menu;
			} else {
				menu = menus[menus.length - 1];
			}
		}
		return menu;
	}

	menu$ = this.transfer
		.transfer$("GetMainMenu", () =>
			this.http.post("/api/statement/GetMainMenu", { vars: { app_menuid: 1, auth_roleid: 7 } }),
		)
		.pipe(
			map((response: any) => {
				return response.results.reduce((acc: any, row: any) => {
					const menu: any = {
						text: row.app_menu_item,
						link: row.custom_url,
						target: row.url_target,
						external: row.custom_url ? row.custom_url.indexOf("http") === 0 : false,
						megaMenu:
							row.app_menu_item.toLowerCase().indexOf("new") !== -1 ||
							row.app_menu_item.toLowerCase().indexOf("pre-owned") !== -1 ||
							row.app_menu_item.toLowerCase().indexOf("clearance") !== -1,
						visible: false,
						new: row.app_menu_item.toLowerCase().indexOf("new") !== -1,
						used: row.app_menu_item.toLowerCase().indexOf("pre-owned") !== -1,
						clearance: row.app_menu_item.toLowerCase().indexOf("clearance") !== -1,
						items: [],
					};

					if (row.sub_menu_stmtid !== null) {
						menu.items = [];
						menu.stmtid = row.sub_menu_stmtid;
						menu.cssClasses = "stmtmenu";
						menu.items$ = this.transfer
							.transfer$("Stmt:" + row.sub_menu_stmtid, () =>
								this.http.post("/api/statement/" + row.sub_menu_stmtid, {
									vars: { app_menuid: 1, auth_roleid: 7, condition: "new", cutout: true },
								}),
							)
							.pipe(
								map((response: any) =>
									response.results.map((subRow: any) => ({
										text: subRow.name,
										link: subRow.content_page_url,
										megaMenu: subRow.name.toLowerCase().indexOf("new") !== -1,
										visible: false,
										external: false,
										items: [],
									})),
								),
								shareReplay(1),
							);
					}

					if (row.of_depth > 0) {
						const subMenu = this.getSubMenu(acc, row.of_depth);
						if (subMenu) {
							subMenu.items.push(menu);
						}
					} else {
						acc.push(menu);
					}
					return acc;
				}, []);
			}),
		);
}
