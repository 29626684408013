import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SubmitButtonComponent } from "./components/submit-button/submit-button.component";

@NgModule({
	declarations: [SubmitButtonComponent],
	imports: [CommonModule, FontAwesomeModule],
	exports: [SubmitButtonComponent],
})
export class ButtonModule {}
