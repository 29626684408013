import { Component, Input } from "@angular/core";

@Component({
	selector: "cm-image2",
	template: `
		<ng-container *ngIf="image">
			<ng-container *ngIf="image.disableLazyLoad; else lazyLoad">
				<img
					class="img-fluid {{ image.imgClass }}"
					[src]="image.src"
					[alt]="image.alt"
					[title]="image.title || image.alt"
					[attr.width]="image.width"
					[attr.height]="image.height"
					itemprop="image"
				/>
			</ng-container>
		</ng-container>
		<ng-template #lazyLoad>
			<img
				loading="lazy"
				[alt]="image.alt"
				[title]="image.title || image.alt"
				class="img-fluid {{ image.imgClass }}"
				[src]="image.src"
				[attr.width]="image.width"
				[attr.height]="image.height"
				itemprop="image"
			/>
		</ng-template>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			img {
				object-fit: contain;
				object-position: 50% 50%;
				margin: auto;
			}
		`,
	],
})
export class Image2Component {
	@Input() image: any;
}
