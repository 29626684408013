import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { IStmtResults } from "@model/stmt-results";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class PromotionsService {
	promotions$: Observable<IStmtResults<any>>;

	constructor(http: HttpClient, transfer: TransferRxService) {
		this.promotions$ = transfer.transfer$("GetPopupPromotions", () =>
			http.post("/api/statement/GetPopupPromotions", {}).pipe(shareReplay(1)),
		);
	}
}
